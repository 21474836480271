import logoWhite from '../../assets/images/priority-logo-white.png'
import logoBlack from '../../assets/images/priority-logo-black.png'

const PriorityLogo = ({ color }) => {
  if (color === 'white') {
    return <img src={logoWhite} alt='Priority' className='logo' />
  } else {
    return <img src={logoBlack} alt='Priority' className='logo' />
  }
}

export default PriorityLogo
