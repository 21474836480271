import {
  Paper,
  Typography,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppContext } from '../../../context/appContext'
import Wrapper from '../../../wrappers/Form'
import { AlertBox, Loading } from '../../../components'
import { useValidate } from '../../../hooks'
import { convertDateByLocal } from '../../../utils'

const User = () => {
  const { id } = useParams()
  const [isEditing] = useState(id !== 'add')
  const { authFetch } = useAppContext()
  const [initLoading, setInitLoading] = useState(isEditing)
  const [loading, setLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState({
    open: false,
  })
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    verified: false,
    role: 'USER',
  })
  const navigate = useNavigate()

  const fetchData = async () => {
    try {
      setLoading(true)
      const { data } = await authFetch(`/users/${id}`)

      setUserData(data.user)
      setInitLoading(false)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
    }
  }

  const fetchSave = async () => {
    console.log(errors)
    try {
      setLoading(true)
      const { data } = await authFetch.patch(`/users/${id}`, {
        ...userData,
      })
      setUserData(data.user)
      setLoading(false)
      setAlertOpen({ open: true, type: 'success', text: 'User updated' })
    } catch (ex) {
      setLoading(false)
      setAlertOpen({
        open: true,
        type: 'error',
        text: 'Server error please try again',
      })
    }
  }

  const changeUserState = ({ name, value }) => {
    setUserData({ ...userData, [name]: value })
  }

  const { handleChange, handleSubmit, errors } = useValidate({
    items: {
      name: {
        value: userData.name,
        onChange: changeUserState,
        required: true,
      },
      email: {
        value: userData.email,
        onChange: changeUserState,
        required: true,
      },
      phone: {
        value: userData.phone,
        onChange: changeUserState,
        required: true,
      },
      verified: {
        value: userData.verified,
        onChange: changeUserState,
      },
      role: {
        value: userData.role,
        onChange: changeUserState,
        required: true,
      },
    },
    nested: true,
  })

  useEffect(() => {
    if (isEditing) {
      fetchData()
    }

    // eslint-disable-next-line
  }, [isEditing])

  return (
    <Wrapper className={`${initLoading && 'loading'}`}>
      {!initLoading ? (
        <Paper sx={{ width: '100%' }} className={'paper'}>
          <Typography variant='h6' gutterBottom>
            User
          </Typography>
          <Box
            component={'form'}
            sx={{
              '& > :not(style)': { m: 1, width: '280px' },
            }}
          >
            <TextField
              name='name'
              label='name*'
              variant='outlined'
              value={userData?.name}
              onChange={handleChange}
              error={errors.name ? true : false}
            />
            <TextField
              name='email'
              label='email'
              variant='outlined'
              helperText={'read only'}
              InputProps={{
                readOnly: true,
              }}
              value={userData.email}
              onChange={handleChange}
              error={errors.email ? true : false}
            />
            <TextField
              name='phone'
              label='phone*'
              variant='outlined'
              value={userData.phone}
              onChange={handleChange}
              error={errors.phone ? true : false}
            />
            <FormControl>
              <InputLabel>Verified</InputLabel>
              <Select
                name='verified'
                label='Verified'
                value={userData.verified}
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Role</InputLabel>
              <Select
                name='role'
                label='Role'
                value={userData.role}
                onChange={handleChange}
              >
                <MenuItem value={'ADMIN'}>Admin</MenuItem>
                <MenuItem value={'USER'}>User</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <div className='detail-info'>
            <p>
              <span className='bold'>registered date: </span>
              {convertDateByLocal({
                date: userData.createdAt,
                format: 'YYYY-MM-DD HH:mm',
              })}
              <br />
              <span className='bold'>verified key: </span>
              {userData.usedTo &&
                userData.usedTo.length > 0 &&
                userData.usedTo[0].verified_date}
              <br />
              <span className='bold'>invitation key: </span>
              {userData.activatedBy?.key}
            </p>
          </div>
          <Stack
            spacing={2}
            direction='row'
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <AlertBox
              open={alertOpen.open}
              setOpen={(open) => setAlertOpen({ open })}
              type={alertOpen.type}
              text={alertOpen.text}
            />
            <LoadingButton variant='text' onClick={() => navigate(-1)}>
              Back
            </LoadingButton>
            <LoadingButton
              variant='contained'
              loading={loading}
              disabled={loading}
              onClick={(e) => handleSubmit(e, fetchSave)}
            >
              Edit
            </LoadingButton>
          </Stack>
        </Paper>
      ) : (
        <Loading color={'black'} />
      )}
    </Wrapper>
  )
}

export default User
