import React from 'react'
import styled from 'styled-components'
import bg from '../assets/images/login-background.png'
import Logo from './Logo/Logo'

const SplashScreen = () => {
  return (
    <Wrapper>
      <Logo color={'white'} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: url(${bg});
  background-size: cover;

  .logo {
    width: 20%;
  }
`

export default SplashScreen
