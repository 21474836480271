import { IoBarChartSharp } from 'react-icons/io5'
import { FiUsers } from 'react-icons/fi'
import { MdProductionQuantityLimits } from 'react-icons/md'
import { BiCategoryAlt, BiPackage } from 'react-icons/bi'
import { BsFillCollectionFill } from 'react-icons/bs'

const Links = [
  {
    id: 1,
    text: 'stats',
    path: '',
    icon: <IoBarChartSharp />,
  },
  {
    id: 2,
    text: 'users',
    path: 'users',
    icon: <FiUsers />,
  },
  {
    id: 3,
    text: 'Categories',
    path: 'category',
    icon: <BiCategoryAlt />,
  },
  {
    id: 4,
    text: 'Collections',
    path: 'collection',
    icon: <BsFillCollectionFill />,
  },
  {
    id: 5,
    text: 'products',
    path: 'product',
    icon: <MdProductionQuantityLimits />,
  },
  {
    id: 6,
    text: 'orders',
    path: 'order',
    icon: <BiPackage />,
  },
]

export default Links
