import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  SET_DATA_TABLE,
  SET_DICTIONARY,
  TOGGLE_MENU,
  TOGGLE_SEARCH,
  SET_USER_INVENTORY,
  CHANGE_LANGUAGE,
} from './actions'

import { initialState } from './appContext'

const reducer = (state, action) => {
  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertType: 'danger',
      alertText: 'Please provide all values!',
    }
  }

  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: '',
      alertText: '',
    }
  }

  /*** USER SETUP ACTIONS ***/
  if (action.type === SETUP_USER_BEGIN) {
    return { ...state, isLoading: true }
  }
  if (action.type === SETUP_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      token: action.payload.token,
      user: action.payload.user,
      userLocation: action.payload.location,
      jobLocation: action.payload.location,
      showAlert: true,
      alertType: 'success',
      alertText: action.payload.alertText,
    }
  }
  if (action.type === SETUP_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    }
  }

  /*** SIDEBAR ACTIONS ***/
  if (action.type === TOGGLE_SIDEBAR) {
    return {
      ...state,
      showSidebar: !state.showSidebar,
    }
  }

  /*** MENU ACTIONS ***/
  if (action.type === TOGGLE_MENU) {
    return {
      ...state,
      showMenu: !state.showMenu,
    }
  }

  /*** SEARCH ACTIONS ***/
  if (action.type === TOGGLE_SEARCH) {
    return {
      ...state,
      showSearch: !state.showSearch,
    }
  }

  if (action.type === LOGOUT_USER) {
    return {
      ...initialState,
      user: null,
      userLocation: '',
      toke: null,
      jobLocation: '',
    }
  }

  /*** USER UPDATE ACTIONS ***/
  if (action.type === UPDATE_USER_BEGIN) {
    return { ...state, isLoading: true }
  }
  if (action.type === UPDATE_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      token: action.payload.token,
      user: action.payload.user,
      userLocation: action.payload.location,
      jobLocation: action.payload.location,
      showAlert: true,
      alertType: 'success',
      alertText: 'User Profile Updated!',
    }
  }
  if (action.type === UPDATE_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    }
  }

  if (action.type === SET_DATA_TABLE) {
    return {
      ...state,
      dataTables: {
        ...state.dataTables,
        [action.payload.key]: action.payload.filter,
      },
    }
  }

  if (action.type === SET_DICTIONARY) {
    return {
      ...state,
      dictionary: {
        ...state.dictionary,
        [action.payload.key]: action.payload.value,
      },
    }
  }

  if (action.type === SET_USER_INVENTORY) {
    return {
      ...state,
      userInventory: action.payload.inventory,
      exchangeAmount: action.payload.amount,
    }
  }

  if (action.type === CHANGE_LANGUAGE) {
    return { ...state, lang: action.payload.lang, i18n: action.payload.i18n }
  }

  throw new Error(`no such action : ${action.type}`)
}

export default reducer
