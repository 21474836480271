export const FILE_TYPE_EMBED = 'EMBED'
export const FILE_TYPE_FILE = 'FILE'
export const FILE_TYPE_FOLDER = 'FOLDER'

export const DB_ROW_ACTIVE = 'ACTIVE'
export const DB_ROW_INACTIVE = 'INACTIVE'

export const SECTION_POSITION_ABOVE = 'ABOVE'
export const SECTION_POSITION_BELOW = 'BELOW'
export const SECTION_MAX_LENGTH = 10

export const BLOG_TYPE_NEWS = 'NEWS'
export const BLOG_TYPE_KNOW = 'KNOW'

export const PAGE_KEY_INTO = 'INTRO'
export const PAGE_KEY_VISION = 'VISION'
export const PAGE_KEY_ADVICE = 'ADVICE'
export const PAGE_KEY_CODE = 'CODE'
export const PAGE_KEY_IMPORT_EXPORT = 'IMPORT_EXPORT'
export const PAGE_KEY_TRAIN = 'TRAIN'
export const PAGE_KEY_AUTO = 'AUTO'
export const PAGE_KEY_SPECIAL = 'SPECIAL'
export const PAGE_KEY_TRANSIT = 'TRANSIT'
export const PAGE_KEY_CRANE = 'CRANE'
export const PAGE_KEY_CUSTOMS = 'CUSTOMS'
export const PAGE_KEY_JOB = 'JOB'

export const FILE_EXPLORER_ACTION_OPEN = 'OPEN'
export const FILE_EXPLORER_ACTION_RENAME = 'RENAME'
export const FILE_EXPLORER_ACTION_DOWNLOAD = 'DOWNLOAD'
export const FILE_EXPLORER_ACTION_INFO = 'INFO'
export const FILE_EXPLORER_ACTION_DELETE = 'DELETE'

export const DICTIONARY_CATEGORY = 'CATEGORY'
export const DICTIONARY_COLLECTION = 'COLLECTION'
export const SHOP_PRODUCT_LIMIT = 400
export const ORDER_STATUS_PENDING = 'PENDING'
export const ORDER_STATUS_CONFIRMED = 'CONFIRMED'
export const ORDER_STATUS_DECLINED = 'DECLINED'

export const VPatterns = {
  email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/,
  phoneNumber: /^[0-9]{4}[-\s.]?[0-9]{4}$/,
  username:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^[0-9]{8}$/,
  password: /^(?=.*[0-9])(?=.*[a-z]).{8,}$/,
  date: /^\d{4}-\d{2}-\d{2}$/,
  number: /^[0-9]+$/,
  image: /((^|, )(image\/)(jpg|jpeg|png|JPG|JPEG|PNG))+$/,
  nickname: /^[A-Za-z0-9]*[A-Za-z0-9][A-Za-z0-9]*$/,
}
