import styled from 'styled-components'

const Wrapper = styled.div`
  display: block;
  padding: 30px 15px;
  min-height: calc(100vh - var(--shop-nav-height));

  .title {
    font-size: var(--f-size-300);
    color: var(--black);
    text-align: center;
  }

  .route-map {
    font-size: var(--f-size-100);
    color: var(--black);
    text-align: center;
    padding: 30px 0;
    word-wrap: break-word;
    span {
      cursor: pointer;

      &:hover {
        font-weight: 500;
      }
    }
  }

  .self {
    font-weight: 500;
  }

  .loading-container {
    width: 100%;
    height: calc(100vh - var(--shop-nav-height) - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cart-buttons {
    width: 100%;
    text-align: center;
    button {
      width: 200px;
    }
  }
`

export default Wrapper
