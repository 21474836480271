import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import RowNumberCell from './Cells/RowNumberCell'
import Loading from '../Loading'

const DataTableV = ({
  rowsPerPage,
  page,
  count,
  onChangePage,
  onChangeRowsPerPage,
  columns: rowColumns,
  rows,
  loading,
}) => {
  const columns = [
    {
      id: '',
      label: 'No',
      type: 'rowNumber',
      minWidth: 10,
      render: ({ rowIndex }) => {
        return (
          <RowNumberCell page={page} perPage={rowsPerPage} index={rowIndex} />
        )
      },
    },
    ...rowColumns,
  ]

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.headerRender ? column.headerRender() : column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              rows.map((row, rowIndex) => {
                return (
                  <TableRow hover role='checkbox' key={`row-${rowIndex}`}>
                    {columns.map((column) => {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.render
                            ? column.render({ rowIndex, row })
                            : row[column.id]}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })
            ) : (
              <TableRow hover role='checkbox'>
                <TableCell
                  align={'center'}
                  style={{ minWidth: '100%' }}
                  colSpan={columns.length}
                >
                  <Loading color={'black'} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {onChangePage && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          labelDisplayedRows={({ count, page }) =>
            `${page + 1} - ${Math.ceil(count / rowsPerPage)}`
          }
          component='div'
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      )}
    </Paper>
  )
}

export default DataTableV
