export const DISPLAY_ALERT = 'SHOW_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'

export const SETUP_USER_BEGIN = 'SETUP_USER_BEGIN'
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS'
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH'
export const LOGOUT_USER = 'LOGOUT_USER'

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const SET_DATA_TABLE = 'SET_DATA_TABLE'
export const SET_DICTIONARY = 'SET_DICTIONARY'
export const SET_USER_INVENTORY = 'SET_USER_INVENTORY'

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
