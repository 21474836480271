import logoWhite from '../../assets/images/db-logo-white.png'
import logoBlack from '../../assets/images/db-logo-black.png'

const DBLogo = ({ color }) => {
  if (color === 'white') {
    return <img src={logoWhite} alt='De Beers Forever mark' className='logo' />
  } else {
    return <img src={logoBlack} alt='De Beers Forever mark' className='logo' />
  }
}

export default DBLogo
