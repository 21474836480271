import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAppContext } from '../../context/appContext'

const ShopCollections = ({ data, short, onMore }) => {
  const navigate = useNavigate()
  const { i18n, lang } = useAppContext()

  const prepare = (rows) => {
    const array = [...rows]
    const nestedArr = []

    for (let i = 0; i < array.length; i += 2) {
      nestedArr.push(array.slice(i, i + 2))
    }

    return nestedArr
  }

  return (
    <Wrapper>
      <div className='title'>{i18n.collections}</div>
      {prepare(data).map((row, index) => {
        if (short && index >= 2) {
          return null
        }

        return (
          <div className='row' key={index}>
            {row.map((item, itemIndex) => {
              return (
                <div
                  key={itemIndex}
                  className='item'
                  onClick={() =>
                    navigate(`/collection/${item.id}`, {
                      state: { id: item.id },
                    })
                  }
                >
                  <div className='img'>
                    <img src={item.cover} alt={item.name_en} />
                  </div>
                  <p className='name'>
                    {lang === 'ENG' ? item.name_en : item.name_mn}
                  </p>
                </div>
              )
            })}
          </div>
        )
      })}
      {short && (
        <div className='more'>
          <span onClick={onMore}>More...</span>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 27px;
  width: 100%;

  .title {
    font-size: var(--f-size-300);
    color: var(--black);
    margin-bottom: 28px;
    font-weight: 600;
  }

  .name {
    font-size: var(--f-size-200);
    text-align: center;
  }

  .row {
    text-align: center;
  }

  .item {
    cursor: pointer;
    display: inline-block;
    margin: 0 2px;
  }

  .img {
    width: 484px;
    height: 322px;
    overflow: hidden;
    &:hover img {
      transform: scale(1.1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    object-fit: cover;
  }

  .more {
    text-align: center;
    color: var(--black);

    span {
      cursor: pointer;
    }
  }

  @media (max-width: 484px) {
    .img {
      width: 100%;
      height: 322px;
      overflow: hidden;
      &:hover img {
        transform: scale(1.1);
      }
    }
  }
`

export default ShopCollections
