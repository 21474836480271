import { Collapse, Alert } from '@mui/material'
import { useEffect } from 'react'

const AlertBox = ({ open, setOpen, type, text }) => {
  useEffect(() => {
    if (open) {
      const timeout = setTimeout(() => setOpen(false), 3000)

      return () => {
        clearTimeout(timeout)
      }
    }

    // eslint-disable-next-line
  }, [open])

  return (
    <Collapse in={open}>
      <Alert severity={`${type ? type : 'info'}`}>{text}</Alert>
    </Collapse>
  )
}

export default AlertBox
