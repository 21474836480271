import { useAppContext } from '../../../context/appContext'
import { useState, useEffect } from 'react'
import { objToQuery } from '../../../utils'
import {
  DataTableV,
  ImageCell,
  MenuCell,
  StatusCell,
} from '../../../components'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { DB_ROW_ACTIVE, DB_ROW_INACTIVE } from '../../../utils/constants'
import Wrapper from '../../../wrappers/ListWrapper'

const CategoryList = () => {
  const { authFetch, setDataTableFilter, dataTables } = useAppContext()
  const [rowsData, setRowsData] = useState([])
  const [rowsTotalCount, setRowsTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const defaultFilter = { search: '', isActive: 'ALL', page: 0, limit: 10 }
  const pageKey = 'CategoryList'
  const [filter, setFilter] = useState(
    dataTables[pageKey] ? dataTables[pageKey] : defaultFilter
  )

  const fetchData = async () => {
    try {
      setLoading(true)
      setDataTableFilter({ pageKey, filter })
      const { data } = await authFetch(
        `category${objToQuery({
          ...filter,
          page: filter.page + 1,
        })}`
      )

      setRowsData(data.rows)
      setRowsTotalCount(data.count)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
      setRowsData([])
      setRowsTotalCount(0)
    }
  }

  const handleChangePage = (event, newPage) => {
    filter.page = newPage
    setFilter({ ...filter })
    fetchData()
  }

  const handleChangeRowsPerPage = (event) => {
    filter.limit = event.target.value
    filter.page = 0
    setFilter({ ...filter })
    fetchData()
  }

  const handleFilterChange = (event) => {
    const { value, name } = event.target
    setFilter({ ...filter, [name]: value })
  }

  useEffect(() => {
    fetchData()

    // eslint-disable-next-line
  }, [])

  const columns = [
    { id: 'name_mn', label: 'name mn' },
    { id: 'name_en', label: 'name_en' },
    {
      id: 'cover',
      label: 'photo',
      render: ({ row }) => {
        return <ImageCell src={row.cover} />
      },
    },
    {
      id: 'isActive',
      label: 'status',
      render: ({ row }) => {
        return (
          <StatusCell
            status={row.isActive === DB_ROW_ACTIVE ? 'success' : ''}
            text={row.isActive === DB_ROW_ACTIVE ? 'active' : 'inactive'}
          />
        )
      },
    },
    {
      id: 'menu',
      label: '',
      type: 'rowNumber',
      headerRender: () => {
        return (
          <Button
            variant='text'
            onClick={() => navigate('/dashboard/category/add')}
          >
            Add
          </Button>
        )
      },
      render: ({ row }) => {
        return (
          <MenuCell
            items={[
              {
                text: 'view',
                onClick: () => {
                  console.log(row)
                },
              },
              {
                text: 'edit',
                onClick: () => {
                  navigate(`/dashboard/category/${row.id}`)
                },
              },
            ]}
          />
        )
      },
    },
  ]

  return (
    <Wrapper>
      <Stack
        spacing={2}
        direction='row'
        alignItems={'center'}
        justifyContent={'flex-start'}
        mb={'20px'}
      >
        <TextField
          name='search'
          label='search'
          variant='outlined'
          value={filter.search}
          onChange={handleFilterChange}
          size={'small'}
        />
        <FormControl size={'small'} className='form-field'>
          <InputLabel>status</InputLabel>
          <Select
            name='isActive'
            label='status'
            value={filter.isActive}
            onChange={handleFilterChange}
          >
            <MenuItem value={'ALL'}>All</MenuItem>
            <MenuItem value={DB_ROW_ACTIVE}>Active</MenuItem>
            <MenuItem value={DB_ROW_INACTIVE}>Inactive</MenuItem>
          </Select>
        </FormControl>
        <Button variant='contained' disabled={loading} onClick={fetchData}>
          Search
        </Button>
        <Button
          variant='text'
          disabled={loading}
          onClick={() => setFilter(defaultFilter)}
        >
          Clear
        </Button>
      </Stack>
      <DataTableV
        rows={rowsData}
        columns={columns}
        rowsPerPage={filter.limit}
        page={filter.page}
        count={rowsTotalCount}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
    </Wrapper>
  )
}

export default CategoryList
