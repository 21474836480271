import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../../context/appContext'
import { formatAmount } from '../../../utils'
import {
  ORDER_STATUS_CONFIRMED,
  ORDER_STATUS_DECLINED,
} from '../../../utils/constants'
import Portal from '../../Portal'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Stack,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import AlertBox from '../AlertBox'

const OrderStatusChangeModal = ({ open, close, data, submit }) => {
  const [rollback, setRollback] = useState('YES')
  const [alertOpen, setAlertOpen] = useState({
    open: false,
  })
  const [loading, setLoading] = useState(false)
  const { authFetch } = useAppContext()
  const [status, setStatus] = useState(ORDER_STATUS_CONFIRMED)
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (!open) {
      setDescription('')
      setStatus(ORDER_STATUS_CONFIRMED)
      setRollback('YES')
    }
  }, [open])

  const changeStatus = async () => {
    try {
      setLoading(true)
      if (status === ORDER_STATUS_CONFIRMED) {
        await authFetch.post('order/confirm', {
          order_id: data.id,
          description,
        })
      } else if (status === ORDER_STATUS_DECLINED) {
        await authFetch.post('order/decline', {
          order_id: data.id,
          description,
          rollback,
        })
      }
      submit()
      setLoading(false)
    } catch (ex) {
      setLoading(false)
      setAlertOpen({ open: true, text: ex.response.data.msg, type: 'error' })
    }
  }

  return (
    open && (
      <Portal>
        <Wrapper>
          <div className={`wrapper`}>
            <div className='title'>{data.order_num}</div>
            <div className='text'>
              customer name: <span className='bold'>{data.ownedBy.name}</span>
            </div>
            <div className='text'>
              customer email: <span className='bold'>{data.ownedBy.email}</span>
            </div>
            <div className='text'>
              customer phone: <span className='bold'>{data.ownedBy.phone}</span>
            </div>
            <div className='text'>
              total amount:{' '}
              <span className='bold'>
                {formatAmount({ amount: data.total_amount })}
              </span>
            </div>
            <div className='text'>
              pre amount:{' '}
              <span className='bold'>
                {formatAmount({ amount: data.pre_amount })}
              </span>
            </div>
            <div className='text'>
              total amount ₮:{' '}
              <span className='bold'>
                {formatAmount({
                  amount: data.total_amount * data.exchange_rate,
                })}
              </span>
            </div>
            <div className='text'>
              pre amount ₮:{' '}
              <span className='bold'>
                {formatAmount({ amount: data.pre_amount * data.exchange_rate })}
              </span>
            </div>
            <div className='text'>
              status: <span className='bold'>{data.statuses[0].status}</span>
            </div>
            <div className='field'>
              <FormControl fullWidth>
                <InputLabel>status change to</InputLabel>
                <Select
                  name='status'
                  label='status change to'
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={ORDER_STATUS_CONFIRMED}>Confirm</MenuItem>
                  <MenuItem value={ORDER_STATUS_DECLINED}>Decline</MenuItem>
                </Select>
              </FormControl>
            </div>
            {status === ORDER_STATUS_DECLINED && (
              <div className='field'>
                <FormControl fullWidth>
                  <InputLabel>rollback order products quantity</InputLabel>
                  <Select
                    name='rollback'
                    label='rollback order products quantity'
                    value={rollback}
                    onChange={(e) => setRollback(e.target.value)}
                  >
                    <MenuItem value={'YES'}>Yes</MenuItem>
                    <MenuItem value={'NO'}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
            <div className='field'>
              <TextField
                name='status_description'
                label='status description'
                variant='outlined'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={5}
                fullWidth
              />
            </div>
            <Stack
              spacing={2}
              direction='row'
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <AlertBox
                open={alertOpen.open}
                setOpen={(open) => setAlertOpen({ open })}
                type={alertOpen.type}
                text={alertOpen.text}
              />
              <LoadingButton variant='text' onClick={close}>
                close
              </LoadingButton>
              <LoadingButton
                variant='contained'
                loading={loading}
                disabled={loading}
                onClick={changeStatus}
              >
                Change
              </LoadingButton>
            </Stack>
          </div>
        </Wrapper>
      </Portal>
    )
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  top: 0;
  left: 0;
  padding: 20px;

  .wrapper {
    background: var(--white);
    padding: 30px;
    font-size: var(--f-size-100);
    color: var(--black);
    max-width: 600px;
    border-radius: 20px;
  }

  .title {
    text-align: center;
    font-size: var(--f-size-200);
    margin-bottom: 10px;
    font-weight: 700;
  }
  .bold {
    font-weight: 700;
  }

  .field {
    width: 100%;
    margin: 10px 0;
  }
`

export default OrderStatusChangeModal
