import { formatDate } from '../../utils'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useAppContext } from '../../context/appContext'
import { LoadingButton } from '@mui/lab'
import { ORDER_STATUS_PENDING } from '../../utils/constants'
import AlertBox from '../Dashboard/AlertBox'

const ShopOrdersItem = ({ item, status }) => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const { authFetch, i18n } = useAppContext()
  const [alertOpen, setAlertOpen] = useState({
    open: false,
  })

  const decline = async () => {
    try {
      setLoading(true)
      await authFetch.post('order/decline', {
        order_id: item.id,
        rollback: 'YES',
        description: 'DECLINED BY OWNER',
      })
      setLoading(false)
    } catch (ex) {
      setLoading(false)
      setAlertOpen({
        open: true,
        type: 'error',
        text: i18n.cannotBeDeclined,
      })
    }
  }

  return (
    <div className={`order-item ${status.status}`}>
      <div
        className='order-info'
        onClick={() =>
          navigate(`/order/${item.id}`, { state: { id: item.id } })
        }
      >
        <div className='order-num'>{item.order_num}</div>
        <div className='order-date'>{formatDate({ date: item.createdAt })}</div>
        <div className='order-status'>{i18n[status.status]}</div>
      </div>
      <AlertBox
        open={alertOpen.open}
        setOpen={(open) => setAlertOpen({ open })}
        type={alertOpen.type}
        text={alertOpen.text}
      />
      {status.status === ORDER_STATUS_PENDING && (
        <LoadingButton
          variant='text'
          onClick={decline}
          loading={loading}
          disabled={loading}
        >
          Decline
        </LoadingButton>
      )}
    </div>
  )
}

export default ShopOrdersItem
