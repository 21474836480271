import { useEffect, useState } from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { AuthLayout, ShopLayout, SplashScreen } from './components'
import { useAppContext } from './context/appContext'
import {
  Error,
  ProtectedRoute,
  Login,
  Register,
  Verify,
  Home,
  SendResetPassword,
  ResetPassword,
  Collections,
  CollectionProducts,
  CategoryProducts,
  ShopProduct,
  ShopSearch,
  Profile,
  ShopMyCard,
  ShopSendOrder,
} from './pages'
import {
  News,
  Stats,
  SharedLayout,
  Users,
  User,
  CategoryList,
  Category,
  CollectionList,
  Collection,
  ProductsList,
  Product,
  OrderList,
  OrderDetail,
} from './pages/dashboard'
import {
  DICTIONARY_CATEGORY,
  DICTIONARY_COLLECTION,
  DB_ROW_ACTIVE,
} from './utils/constants'
import { objToQuery } from './utils'

function App() {
  const [loading, setLoading] = useState(true)
  const { user, authFetch, setDictionary, setUserInventory } = useAppContext()

  const initLoad = async () => {
    try {
      setLoading(true)
      const { data: catData } = await authFetch(
        `category${objToQuery({
          page: 1,
          limit: 100,
          isActive: DB_ROW_ACTIVE,
        })}`
      )

      const { data: colData } = await authFetch(
        `collections${objToQuery({
          page: 1,
          limit: 100,
          isActive: DB_ROW_ACTIVE,
        })}`
      )

      // const { data: inventory } = await authFetch(`userinventory`)
      const inventory = {}
      setUserInventory({
        inventory: inventory.rows ? inventory.rows : [],
        amount: inventory.exchange_amount ? inventory.exchange_amount : 0,
      })

      setDictionary({
        dicKey: DICTIONARY_CATEGORY,
        dicVal: catData.rows.reverse(),
      })
      setDictionary({
        dicKey: DICTIONARY_COLLECTION,
        dicVal: colData.rows.reverse(),
      })

      setLoading(false)
    } catch (ex) {
      setLoading(ex)
    }
  }

  useEffect(() => {
    initLoad()

    // eslint-disable-next-line
  }, [])

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/auth' element={<AuthLayout />}>
          <Route index element={<Login />} />
          <Route path='register' element={<Register />} />
          <Route path='verify' element={<Verify />} />
          <Route path='verify/:code' element={<Verify />} />
          <Route path='forget' element={<SendResetPassword />} />
          <Route path='reset/:code' element={<ResetPassword />} />
        </Route>
        <Route
          path='/'
          element={
            <ProtectedRoute>
              <ShopLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Home />} />
          <Route path='collections' element={<Collections />} />
          <Route path='collection/:id' element={<CollectionProducts />} />
          <Route path='category/:id' element={<CategoryProducts />} />
          <Route path='product/:id' element={<ShopProduct />} />
          <Route path='search' element={<ShopSearch />} />
          <Route path='profile' element={<Profile />} />
          <Route path='my-cart' element={<ShopMyCard />} />
          <Route path='order' element={<ShopSendOrder />} />
          <Route path='order/:id' element={<ShopSendOrder />} />
        </Route>
        <Route
          path='/dashboard'
          element={
            <ProtectedRoute role='ADMIN'>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Stats />} />
          <Route path='users' element={<Users />} />
          <Route path='users/:id' element={<User />} />
          <Route path='category' element={<CategoryList />} />
          <Route path='category/:id' element={<Category />} />
          <Route path='collection' element={<CollectionList />} />
          <Route path='collection/:id' element={<Collection />} />
          <Route path='product' element={<ProductsList />} />
          <Route path='product/:id' element={<Product />} />
          <Route path='news' element={<News />} />
          <Route path='order' element={<OrderList />} />
          <Route path='order/:id' element={<OrderDetail />} />
        </Route>
        <Route path='*' element={<Error />} />
      </>
    )
  )

  return loading ? <SplashScreen /> : <RouterProvider router={router} />
}

export default App
