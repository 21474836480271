import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 300px;
  margin: 0 auto;

  .buttons {
    margin-top: 24px;
    padding: 0 73px;
  }

  .full-link {
    width: 100%;
    margin-top: 12px;
    color: var(--black);
    font-size: var(--f-size-100);
    font-weight: 400;
    text-align: center;

    a {
      color: var(--black);
    }

    .bold {
      font-weight: 600;
      cursor: pointer;
    }
  }

  .error {
    color: var(--red-dark);
    font-size: var(--f-size-50);
    padding: 10px;
    margin: 0;
  }

  .thin {
    padding: 30px 20px;
  }

  .logoutBtn {
    margin-top: 80px;
  }

  .lang {
    cursor: pointer;
  }

  h5 {
    text-align: center;
    color: var(--black);
  }

  @media (max-width: 1024px) {
    .full-link {
      color: var(--white);
      a {
        color: var(--white);
      }
    }

    h5 {
      color: var(--white);
    }
  }
`

export default Wrapper
