import { useMediaQuery } from '@mui/material'

const useScreen = () => {
  const isWide = useMediaQuery('(min-width:1025px)')
  const isTablet = useMediaQuery('(max-width:1024px) and (min-width:769px)')
  const isMobile = useMediaQuery('(max-width:768px')

  return { isWide, isTablet, isMobile }
}

export default useScreen
