import styled from 'styled-components'

import { Partners, ShopOrders } from '../../components'
import profileBanner from '../../assets/images/profile-banner-en.jpg'
import profileBannerMn from '../../assets/images/profile-banner-mn.jpg'
import { useEffect, useState } from 'react'
import { useAppContext } from '../../context/appContext'

const Profile = () => {
  const { authFetch, i18n, lang } = useAppContext()
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const getOrders = async () => {
    try {
      setLoading(true)
      const { data: orders } = await authFetch('order/userOrders')
      setData(orders.rows)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
      console(ex)
    }
  }

  useEffect(() => {
    getOrders()

    // eslint-disable-next-line
  }, [])

  return (
    <Wrapper>
      <div className='profile-banner'>
        {lang === 'ENG' ? (
          <img src={profileBanner} alt='profile banner' />
        ) : (
          <img src={profileBannerMn} alt='profile banner' />
        )}
      </div>
      <ShopOrders items={data} />
      <div className='partners-home'>
        <div className='partners-home-title'>{i18n.partners}</div>
        <Partners color={'black'} />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .profile-banner {
    width: 100%;

    img {
      width: 100%;
    }
  }
  .partners-home {
    margin-top: 56px;
  }

  .partners-home-title {
    font-size: var(--f-size-400);
    width: 100%;
    margin-top: 56px;
    text-align: center;
    color: var(--black);
  }
`

export default Profile
