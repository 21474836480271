import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAppContext } from '../../context/appContext'
import { formatAmount } from '../../utils'
import Button from '../Buttons/Button'

const ShopProductDetail = ({ data, decrease, increase, value }) => {
  const soldOut = data.inventory.quantity === 0 ? true : false

  const [loading, setLoading] = useState(false)
  const [loadingNow, setLoadingNow] = useState(false)
  const navigate = useNavigate()
  const { authFetch, setUserInventory, i18n, lang } = useAppContext()

  const addToCart = async () => {
    try {
      setLoading(true)

      const { data: resData } = await authFetch.post(`userinventory`, {
        product_id: data.id,
        quantity: value,
      })
      setUserInventory({
        inventory: resData.rows ? resData.rows : [],
        amount: resData.exchange_amount ? resData.exchange_amount : 0,
      })

      setLoading(false)
    } catch (ex) {
      setLoading(false)
      console.log(ex)
    }
  }

  const orderNow = async () => {
    try {
      setLoadingNow(true)

      const { data: resData } = await authFetch.post(`userinventory`, {
        product_id: data.id,
        quantity: value,
      })
      setUserInventory({
        inventory: resData.rows ? resData.rows : [],
        amount: resData.exchange_amount ? resData.exchange_amount : 0,
      })

      setLoadingNow(false)
      navigate('/order')
    } catch (ex) {
      setLoadingNow(false)
      console.log(ex)
    }
  }

  return (
    <Wrapper>
      <div className='item cover'>
        <img src={data.cover} alt={data.product_num} />
        {soldOut && <div className='sold-out'>{i18n.soldOut}</div>}
      </div>
      <div className='item info'>
        <div className='num'>{data.product_num}</div>
        <div className='description'>
          {i18n.productDescription}
          <br />
          <br />
          {lang === 'ENG' ? data.description_en : data.description_mn}
        </div>
        <div className='inventory first'>
          <div className='price'>
            {i18n.price}: {formatAmount({ amount: data.inventory.price })}
          </div>
          {/* <div>{i18n.quantity}</div> */}
        </div>
        <div className='inventory second'>
          <div className='size'>
            {i18n.size}: {data.size ? data.size : 'N/A'}
          </div>
          {/* <div className='size'>
            {i18n.inStock}: {data.inventory.quantity}
          </div> */}
        </div>
        <div className='details'>
          <div className='details-items'>
            <div className='details-title'>{i18n.details}:</div>
            {Object.keys(data.details_en).map((key, index) => {
              const value = data.details_en[key]
              return (
                <Fragment key={index}>
                  - {key}: {value}
                  <br />
                </Fragment>
              )
            })}
          </div>
        </div>
        {/* {!soldOut && (
          <>
            <div className='quantity'>
              <div className='btn' onClick={decrease}>
                -
              </div>
              <input type={'text'} readOnly value={value}></input>
              <div className='btn' onClick={increase}>
                +
              </div>
            </div>
            <div className='order-buttons'>
              <Button
                text={i18n.addToCart}
                onClick={() => addToCart()}
                loading={loading}
              />
              <Button
                text={i18n.orderNow}
                color={'white'}
                onClick={() => orderNow()}
                loading={loadingNow}
              />
            </div>
          </>
        )} */}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: var(--black);

  .item {
    display: inline-block;
    margin: 15px;
    width: 400px;
  }

  .cover {
    position: relative;
    height: 400px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .sold-out {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: var(--f-size-100);
      background: var(--primary-500);
      padding: 1px 7px;
      color: var(--white);
    }
  }

  .info {
    text-align: left;
    height: auto;
    vertical-align: top;
  }
  .num {
    font-size: var(--f-size-500);
  }

  .description {
    font-size: var(--f-size-100);
  }

  .inventory {
    display: inline-block;
    font-size: var(--f-size-100);
    margin-top: 16px;
  }

  .first {
    margin-right: 64px;
  }

  .price {
    font-weight: 700;
  }

  .quantity {
    input {
      display: inline-block;
      width: 38px;
      height: 38px;
      text-align: center;
      margin: 0 14px;
    }

    .btn {
      display: inline-block;
      vertical-align: middle;
      background: var(--grey-100);
      text-align: center;
      width: 26px;
      cursor: pointer;
      user-select: none;
    }
  }

  .order-buttons {
    margin-top: 30px;
  }

  .details {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: var(--f-size-200);
  }
  .details-items {
    width: fit-content;
    text-align: left;
  }
  @media (max-width: 460px) {
    .item {
      width: 100%;
      margin: 0;
    }
    .cover {
      height: auto;
    }
  }
`

export default ShopProductDetail
