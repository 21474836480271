import styled from 'styled-components'
import { useAppContext } from '../../../context/appContext'
import { useState, useEffect } from 'react'
import { objToQuery } from '../../../utils'
import { DataTableV, DateCell, MenuCell, StatusCell } from '../../../components'
import { useNavigate } from 'react-router-dom'
import { Button, TextField, Stack } from '@mui/material'

const Users = () => {
  const { authFetch, setDataTableFilter, dataTables } = useAppContext()
  const [rowsData, setRowsData] = useState([])
  const [rowsTotalCount, setRowsTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const defaultFilter = { name: '', email: '', phone: '', page: 0, limit: 10 }
  const pageKey = 'UserList'
  const [filter, setFilter] = useState(
    dataTables[pageKey] ? dataTables[pageKey] : defaultFilter
  )
  const navigate = useNavigate()

  const fetchData = async () => {
    try {
      setLoading(true)
      setDataTableFilter({ pageKey, filter })
      const { data } = await authFetch(
        `users${objToQuery({
          ...filter,
          page: filter.page + 1,
        })}`
      )

      setRowsData(data.rows)
      setRowsTotalCount(data.count)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
      setRowsData([])
      setRowsTotalCount(0)
    }
  }

  const handleChangePage = (event, newPage) => {
    filter.page = newPage
    setFilter({ ...filter })
    fetchData()
  }

  const handleChangeRowsPerPage = (event) => {
    filter.limit = event.target.value
    filter.page = 0
    setFilter({ ...filter })
    fetchData()
  }

  const handleFilterChange = (event) => {
    const { value, name } = event.target
    setFilter({ ...filter, [name]: value })
  }

  useEffect(() => {
    fetchData()

    // eslint-disable-next-line
  }, [])

  const columns = [
    { id: 'name', label: 'name' },
    { id: 'email', label: 'email' },
    { id: 'phone', label: 'phone' },
    { id: 'role', label: 'role' },
    {
      id: 'createdAt',
      label: 'registered date',
      render: ({ row }) => {
        return <DateCell date={row.createdAt} />
      },
    },
    {
      id: 'verified',
      label: 'verified',
      render: ({ row }) => {
        return (
          <StatusCell
            status={row.verified ? 'success' : ''}
            text={row.verified ? 'verified' : 'unverified'}
          />
        )
      },
    },
    {
      id: 'menu',
      label: '',
      type: 'rowNumber',
      headerRender: () => {
        return (
          <Button
            variant='text'
            onClick={() => navigate('/dashboard/users/add')}
          >
            Add
          </Button>
        )
      },
      render: ({ row }) => {
        return (
          <MenuCell
            items={[
              {
                text: 'view',
                onClick: () => {
                  console.log(row)
                },
              },
              {
                text: 'edit',
                onClick: () => {
                  navigate(`/dashboard/users/${row.id}`)
                },
              },
            ]}
          />
        )
      },
    },
  ]

  return (
    <Wrapper>
      <Stack
        spacing={2}
        direction='row'
        alignItems={'center'}
        justifyContent={'flex-start'}
        mb={'20px'}
      >
        <TextField
          name='name'
          label='name'
          variant='outlined'
          value={filter.name}
          onChange={handleFilterChange}
          size={'small'}
        />
        <TextField
          name='email'
          label='email'
          variant='outlined'
          value={filter.email}
          onChange={handleFilterChange}
          size={'small'}
        />
        <TextField
          name='phone'
          label='phone'
          variant='outlined'
          value={filter.phone}
          onChange={handleFilterChange}
          size={'small'}
        />
        <Button variant='contained' disabled={loading} onClick={fetchData}>
          Search
        </Button>
        <Button
          variant='text'
          disabled={loading}
          onClick={() => setFilter(defaultFilter)}
        >
          Clear
        </Button>
      </Stack>
      <DataTableV
        rows={rowsData}
        columns={columns}
        rowsPerPage={filter.limit}
        page={filter.page}
        count={rowsTotalCount}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default Users
