import React, { useState } from 'react'
import { Button, FormRow } from '../../components'
import Wrapper from '../../wrappers/Login'
import { useValidate } from '../../hooks'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useAppContext } from '../../context/appContext'

const ResetPassword = () => {
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const navigate = useNavigate()
  const { user, fetch, i18n } = useAppContext()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [reset, setReset] = useState(false)
  const { code } = useParams()

  const { handleChange, handleSubmit, errors } = useValidate({
    items: {
      password: {
        value: password,
        onChange: setPassword,
        required: true,
        minLength: 6,
      },
      passwordRepeat: {
        value: passwordRepeat,
        onChange: setPasswordRepeat,
        required: true,
        minLength: 6,
      },
    },
  })

  const fetchReset = async () => {
    if (password !== passwordRepeat) {
      setError(i18n.passwordDoesNotMatch)
      return false
    }

    try {
      setLoading(true)
      await fetch.patch('/auth/reset', { password, key: code })
      setReset(true)
      setError(null)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
      setError(i18n.invalidResetKey)
    }
  }

  useEffect(() => {
    if (user) {
      navigate('/')
    }

    // eslint-disable-next-line
  }, [user])

  return (
    <Wrapper>
      {reset ? (
        <p className='full-link'>{i18n.resetPasswordSuccessfully}</p>
      ) : (
        <>
          <h5>{i18n.resetPassword}</h5>
          <FormRow
            type={'password'}
            name={'password'}
            value={password}
            handleChange={handleChange}
            labelText={'New password'}
            hideLabel={true}
          />
          <FormRow
            type={'password'}
            name={'passwordRepeat'}
            value={passwordRepeat}
            handleChange={handleChange}
            labelText={'New password repeat'}
            hideLabel={true}
          />
          {(Object.keys(errors).length > 0 || error) && (
            <p className='error'>{error ? error : 'Invalid password'}</p>
          )}
          <div className='buttons'>
            <Button
              text={'Reset'}
              onClick={(e) => handleSubmit(e, fetchReset)}
              loading={loading}
            />
          </div>
        </>
      )}
      <p className='full-link'>
        <Link to={'/auth'} className={'bold'}>
          {' Login?'}
        </Link>
      </p>
    </Wrapper>
  )
}

export default ResetPassword
