import Wrapper from '../../wrappers/ShopNavBar'
import { Link, useNavigate } from 'react-router-dom'
import { useAppContext } from '../../context/appContext'
import { DICTIONARY_CATEGORY } from '../../utils/constants'
import { Fragment } from 'react'
import { IoSearch } from 'react-icons/io5'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { AiOutlineMenu } from 'react-icons/ai'
import { calcUserInventoryLength } from '../../utils'
import { CgProfile } from 'react-icons/cg'

const ShopNavBar = () => {
  const {
    dictionary,
    toggleMenu,
    toggleSearch,
    userInventory,
    lang,
    changeLanguage,
    i18n,
  } = useAppContext()
  const navigate = useNavigate()

  return (
    <Wrapper>
      <div className='east'>
        <div className='logo' onClick={() => navigate('/')}>
          <h5>DIAMOND & JEWELLERY</h5>
          <p className='h6'>PRIORITY EXHIBITION</p>
        </div>
        <div className='collection' onClick={() => navigate('/collections')}>
          {i18n.collections}
        </div>
      </div>
      <div className='west'>
        <div className='categories'>
          {dictionary[DICTIONARY_CATEGORY].map((item, index) => {
            return (
              <Fragment key={index}>
                {index > 0 && '|'}
                <div>
                  <Link to={`/category/${item.id}`} state={{ id: item.id }}>
                    {lang === 'ENG' ? item.name_en : item.name_mn}
                  </Link>
                </div>
              </Fragment>
            )
          })}
        </div>
        <div className='profile'>
          <div className='search margined' onClick={toggleSearch}>
            <IoSearch />
          </div>
          {/* <div className='up-line'>|</div> */}
          {/* <div className='pack margined' onClick={() => navigate('/my-cart')}>
            <div className='badge'>
              {calcUserInventoryLength({ inventory: userInventory })}
            </div>
            <HiOutlineShoppingBag className='icon' />
          </div>
          <div className='up-line'>|</div>
          <div
            className='margined profile-text'
            onClick={() => navigate('/profile')}
          >
            <CgProfile className='icon' />
          </div> */}
          <div className='menu-btn' onClick={toggleMenu}>
            <AiOutlineMenu className='icon' />
          </div>
        </div>
        <div className='lang'>
          <span
            className={`${lang === 'ENG' ? 'selected' : ''}`}
            onClick={() => changeLanguage({ lang: 'ENG' })}
          >
            Eng
          </span>{' '}
          |{' '}
          <span
            className={`${lang === 'MN' ? 'selected' : ''}`}
            onClick={() => changeLanguage({ lang: 'MN' })}
          >
            Mon
          </span>
        </div>
      </div>
    </Wrapper>
  )
}

export default ShopNavBar
