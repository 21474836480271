import logo from '../../assets/images/logo.png'
import logoWhite from '../../assets/images/gemma-logo-white.png'
import logoBlack from '../../assets/images/gemma-logo-black.png'

const Logo = ({ color }) => {
  if (color === 'white') {
    return <img src={logoWhite} alt='World Bridge Logistic' className='logo' />
  } else if (color === 'black') {
    return <img src={logoBlack} alt='World Bridge Logistic' className='logo' />
  } else {
    return <img src={logo} alt='World Bridge Logistic' className='logo' />
  }
}

export default Logo
