import styled from 'styled-components'
import Loading from '../Loading'

const Button = ({ text, onClick, disabled, color, loading }) => {
  return (
    <Wrapper
      className={`${color && color}`}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? <Loading color={'black'} /> : text}
    </Wrapper>
  )
}

const Wrapper = styled.button`
  width: 100%;
  background: var(--gold);
  color: var(--black);
  font-size: var(--f-size-100);
  cursor: pointer;
  border-radius: 40px;
  padding: 14px;
  border: none;
  margin-bottom: 12px;
  font-weight: 600;

  &.white {
    background: var(--white);
    border: solid 1px var(--gold);
  }

  &:disabled {
    cursor: default;
  }
`

export default Button
