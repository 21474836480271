import { useNavigate } from 'react-router-dom'
import Wrapper from '../../wrappers/Product'
import { useAppContext } from '../../context/appContext'
import { Button, ShopInventory } from '../../components'

const ShopMyCard = () => {
  const { userInventory, i18n } = useAppContext()
  const navigate = useNavigate()

  return (
    <Wrapper>
      <div className='title'>{i18n.yourProducts}</div>
      <div className='route-map'>
        <span onClick={() => navigate('/')}>{i18n.home}</span>/
        <span className='self'> {i18n.yourProducts}</span>
      </div>
      <ShopInventory items={userInventory} />
      <div className='cart-buttons'>
        <Button text={i18n.orderNow} onClick={() => navigate('/order')} />
      </div>
    </Wrapper>
  )
}

export default ShopMyCard
