import styled from 'styled-components'
import bg from '../../assets/images/about-bg.png'
import { useAppContext } from '../../context/appContext'

const ShopAbout = () => {
  const { lang } = useAppContext()

  return (
    <Wrapper>
      {lang === 'ENG' ? (
        <>
          <div className='title'>
            INFORMATION ON EXHIBITION “A DIAMOND IS FOREVER”
          </div>
          <div className='content'>
            As one of the famous brands in the world, De Beers has been
            marketing exquisite jewelry that highlights the perfection of the
            premium and rare natural gemstone, the Diamond, and makes its
            customers feel its value, since its founding in 1888.
            <br />
            <br />
            Diamonds are the rarest and most premium natural gemstones, and it
            becomes even more valuable as time goes by. This is because it takes
            much time, ingenuity, and money to extract this precious stone from
            natural sources, highlight its unique features and create a shape
            and condition that stands out from others
            <br />
            <br />
            Every diamond of the De Beers brand is extracted in an
            environmentally friendly and ethically mined way and we therefore
            pleased to announce that The HRA Group, the world’s leading company
            performing the entire process from extraction and delivery of the
            Diamond to customers’ hands, officially organizing the exhibition on
            the introduction to the luxury products, for the first time in
            Mongolia, in partnership with Gemma Shine LLC, Khan Bank, and Brinks
            Mongolia LLC.
          </div>
        </>
      ) : (
        <>
          <div className='title'>
            “A DIAMOND IS FOREVER” ҮЗЭСГЭЛЭНГИЙН ТУХАЙ
          </div>
          <div className='content'>
            Дэлхийн алдарт брэндүүдийн нэг De beers нь 1888 онд үүсгэн
            байгуулагдсан цагаасаа хойш дээд зэрэглэлийн, байгалийн ховор үнэт
            чулуу болох алмаз /diamond/-ын төгс төгөлдөр байдлыг улам
            тодотгосон, эрхэм хэрэглэгчдэдээ цаг үргэлж үнэ цэнийг мэдрүүлэх
            үнэт эдлэлийг зах зээлд нийлүүлсээр байна.
            <br />
            <br />
            Алмаз бол байгалийн ховор, дээд зэрэглэлийн үнэт чулуу бөгөөд цаг
            хугацаа улиран одох тусам түүний үнэ цэн нь улам нэмэгдсээр байдаг.
            Үнэт чулууг байгалиас олборлож, дахин давтагдашгүй, өвөрмөц
            онцлогийг нь тодотгон, бусдаас ялгарах дүр төрхийг нь бүтээхэд цаг
            хугацаа, ур ухаан, хөрөнгө хүч ихээр шаардагддаг билээ.
            <br />
            <br />
            De beers брэнд алмазын ширхэг бүрийг байгальд ээлтэй, ёс зүйтэйгээр
            олборлож, алмазыг дахин давтагдашгүй, онцгой ур ухаанаар хийцлэн
            хэрэглэгчийн гарт хүргэдэг бөгөөд олборлолтоос хэрэглэгчийн гарт
            хүрэх үйл явцыг хариуцан гүйцэтгэгч ЭйчАрЭй Групп /The HRA Group/
            Монгол Улсад анх удаа албан ёсны эрхтэйгээр тансаг зэрэглэлийн
            бүтээгдэхүүний танилцуулга арга хэмжээг Жемма Шайн ХХК, Хаан Банк,
            Бринкс Монгол ХХК-тай хамтран зохион байгуулж байгааг дуулгахад
            таатай байна.
          </div>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding: 48px 0;
  background: url(${bg});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    text-align: center;
    color: var(--white);
    width: 100%;
    padding: 15px;
  }

  .title {
    font-size: var(--f-size-300);
    margin-bottom: 32px;
  }

  .content {
    font-size: var(--f-size-200);
    width: 85%;
  }
`

export default ShopAbout
