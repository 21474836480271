import styled from 'styled-components'
import { useAppContext } from '../../context/appContext'

const Alert = () => {
  const { alertType, alertText } = useAppContext()

  return <Wrapper className={`alert alert-${alertType}`}>{alertText}</Wrapper>
}

const Wrapper = styled.div`
  font-size: var(--f-size-50);

  &.alert-danger {
    color: var(--red-dark);
  }
`

export default Alert
