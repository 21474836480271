import React, { useState } from 'react'
import { Button, FormRow, Alert } from '../../components'
import Wrapper from '../../wrappers/Login'
import { VPatterns } from '../../utils/constants'
import { useValidate } from '../../hooks'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useAppContext } from '../../context/appContext'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const { setupUser, isLoading, user, lang, changeLanguage, i18n } =
    useAppContext()

  const { handleChange, handleSubmit, errors, setErrors } = useValidate({
    items: {
      email: {
        value: email,
        pattern: VPatterns.email,
        onChange: setEmail,
        ignoreOn: true,
        required: true,
      },
      password: {
        value: password,
        onChange: setPassword,
        ignoreOn: true,
        required: true,
      },
    },
  })

  const login = async () => {
    await setupUser({
      currentUser: {
        email,
        password,
      },
      endPoint: 'login',
    })
  }

  useEffect(() => {
    setErrors({})

    // eslint-disable-next-line
  }, [email, password])

  useEffect(() => {
    if (user) {
      navigate('/')
    }

    // eslint-disable-next-line
  }, [user])

  return (
    <Wrapper>
      <FormRow
        type={'text'}
        name={'email'}
        value={email}
        handleChange={handleChange}
        labelText={i18n.email}
        hideLabel={true}
      />
      <FormRow
        type={'password'}
        name={'password'}
        value={password}
        handleChange={handleChange}
        labelText={i18n.password}
        hideLabel={true}
      />
      {Object.keys(errors).length > 0 && (
        <p className='error'>{i18n.wrongEmailOrPassword}</p>
      )}
      <Alert />
      <div className='buttons'>
        <Button
          text={i18n.login}
          onClick={(e) => handleSubmit(e, login)}
          loading={isLoading}
        />
        <Button
          text={i18n.register}
          color='white'
          onClick={() => navigate('register')}
        />
      </div>
      <p className='full-link'>
        <Link to={'/auth/forget'}>{i18n.forgetPassword}</Link>
      </p>
      <p className='full-link'>
        <span
          className={`lang ${lang === 'ENG' ? 'bold' : ''}`}
          onClick={() => changeLanguage({ lang: 'ENG' })}
        >
          Eng
        </span>{' '}
        |{' '}
        <span
          className={`lang ${lang === 'MN' ? 'bold' : ''}`}
          onClick={() => changeLanguage({ lang: 'MN' })}
        >
          Mon
        </span>
      </p>
    </Wrapper>
  )
}

export default Login
