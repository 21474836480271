import { Paper, Typography, TextField, Box, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppContext } from '../../../context/appContext'
import Wrapper from '../../../wrappers/Form'
import {
  Loading,
  NestedCell,
  ImageCell,
  DataTableV,
  MenuCell,
  OrderStatusChangeModal,
} from '../../../components'
import { formatAmount, formatDate } from '../../../utils'

const OrderDetail = () => {
  const { id } = useParams()
  const { authFetch } = useAppContext()
  const [initLoading, setInitLoading] = useState(false)
  const [formData, setFormData] = useState()
  const navigate = useNavigate()
  const [rowsData, setRowsData] = useState([])
  const [rowsTotalCount, setRowsTotalCount] = useState(0)
  const [showModal, setShowModal] = useState(false)

  const fetchData = async () => {
    try {
      setInitLoading(true)
      const { data } = await authFetch(`/order/${id}`)
      setFormData(data.order)
      setRowsData(data.order.products)
      setRowsTotalCount(data.order.products.length)
      setInitLoading(false)
    } catch (ex) {
      setInitLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [id])

  const columns = [
    {
      id: 'product_num',
      label: 'Product Number',
      render: ({ row }) => {
        return <NestedCell data={row.product} name={'product_num'} />
      },
    },
    {
      id: 'quantity',
      label: 'quantity',
    },
    {
      id: 'price',
      label: 'price',
      render: ({ row }) => {
        return formatAmount({ amount: row.price })
      },
    },
    {
      id: 'cover',
      label: 'photo',
      render: ({ row }) => {
        return <ImageCell src={row.product.cover} />
      },
    },
    {
      id: 'menu',
      label: '',
      type: 'rowNumber',
      render: ({ row }) => {
        return (
          <MenuCell
            items={[
              {
                text: 'view',
                onClick: () => {
                  navigate(`/product/${row.product.id}`)
                },
              },
            ]}
          />
        )
      },
    },
  ]

  return (
    <Wrapper className={`${initLoading && 'loading'}`}>
      {!initLoading && formData ? (
        <Paper sx={{ width: '100%' }} className={'paper'}>
          <Typography variant='h6' gutterBottom>
            Order Detail
          </Typography>
          <Box
            component={'form'}
            sx={{
              '& > :not(style)': { m: 1, width: '280px' },
            }}
            mt={'20px'}
          >
            <TextField
              name='order_num'
              label='order number'
              variant='outlined'
              value={formData?.order_num}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='name'
              label='ownedBy'
              variant='outlined'
              value={formData?.ownedBy?.name}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='phone'
              label='phone'
              variant='outlined'
              value={formData?.ownedBy?.phone}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='email'
              label='email'
              variant='outlined'
              value={formData?.ownedBy?.email}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='total'
              label='total amount'
              variant='outlined'
              value={formatAmount({ amount: formData?.total_amount })}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='pre'
              label='pre amount'
              variant='outlined'
              value={formatAmount({ amount: formData?.pre_amount })}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='exchange_rate'
              label='rate'
              variant='outlined'
              value={formatAmount({
                amount: formData?.exchange_rate,
                type: 'MN',
              })}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='total_amount'
              label='total amount ₮'
              variant='outlined'
              value={formatAmount({
                amount: formData?.exchange_rate * formData.total_amount,
                type: 'MN',
              })}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='pre_amount'
              label='pre amount ₮'
              variant='outlined'
              value={formatAmount({
                amount: formData?.exchange_rate * formData.pre_amount,
                type: 'MN',
              })}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='date'
              label='ordered date'
              variant='outlined'
              value={formatDate({ date: formData?.createdAt })}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='date'
              label='status date'
              variant='outlined'
              value={formatDate({ date: formData?.statuses[0].createdAt })}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='status'
              label='status'
              variant='outlined'
              value={formData?.statuses[0].status}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='status_description'
              label='status description'
              variant='outlined'
              value={formData?.statuses[0].description}
              InputProps={{
                readOnly: true,
              }}
              multiline
              rows={5}
            />
          </Box>
          <DataTableV
            rows={rowsData}
            columns={columns}
            rowsPerPage={100}
            page={0}
            count={rowsTotalCount}
          />

          <Stack
            spacing={2}
            direction='row'
            alignItems={'center'}
            justifyContent={'flex-end'}
            mt={'10px'}
          >
            <LoadingButton variant='text' onClick={() => navigate(-1)}>
              Back
            </LoadingButton>
            <LoadingButton
              variant='contained'
              onClick={(e) => setShowModal(true)}
            >
              Change status
            </LoadingButton>
          </Stack>

          <OrderStatusChangeModal
            open={showModal}
            close={() => setShowModal(false)}
            data={formData}
            submit={() => {
              setShowModal(false)
              fetchData()
            }}
          />
        </Paper>
      ) : (
        <Loading color={'black'} />
      )}
    </Wrapper>
  )
}

export default OrderDetail
