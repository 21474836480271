import { useAppContext } from '../context/appContext'
import { Navigate, useLocation } from 'react-router-dom'

const ProtectedRoute = ({ children, role }) => {
  const { user } = useAppContext()
  const location = useLocation()

  const isDashboardPath = location.pathname.startsWith('/dashboard')

  if(isDashboardPath){
    if (!user) {
      return <Navigate to='/auth' />
    } else {
      if (!user.verified) {
        return <Navigate to='/auth/verify' />
      }
  
      if (role && role !== user.role) {
        return <Navigate to='/' />
      }
    }
  }

  return children
}

export default ProtectedRoute
