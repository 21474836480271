import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Loading, ShopProducts } from '../../components'
import { useAppContext } from '../../context/appContext'
import { objToQuery } from '../../utils'
import {
  DB_ROW_ACTIVE,
  DICTIONARY_CATEGORY,
  SHOP_PRODUCT_LIMIT,
} from '../../utils/constants'
import Wrapper from '../../wrappers/Product'

const CategoryProducts = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { authFetch, i18n, lang } = useAppContext()
  const [title, setSetTitle] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const [pageId, setPageId] = useState()

  const fetchData = async () => {
    try {
      setLoading(true)
      const { data: resData } = await authFetch.get(
        `/products${objToQuery({
          category_id: pageId,
          limit: SHOP_PRODUCT_LIMIT,
          page: 1,
          isActive: DB_ROW_ACTIVE,
        })}`
      )

      if (resData.rows.length > 0) {
        setSetTitle(
          lang === 'ENG'
            ? resData.rows[0].category.name_en
            : resData.rows[0].category.name_mn
        )
      } else {
        navigate('/404', { replace: true })
      }

      setData(resData.rows)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (pageId) {
      fetchData()
    }

    // eslint-disable-next-line
  }, [pageId])

  useEffect(() => {
    const stateId = location.state?.id
    if (stateId) {
      setPageId(stateId)
    } else {
      setPageId(id)
    }

    // eslint-disable-next-line
  }, [location])

  return (
    <Wrapper>
      {loading ? (
        <div className='loading-container'>
          <Loading color={'black'} />
        </div>
      ) : (
        <>
          <div className='title'>{title}</div>
          <div className='route-map'>
            <span onClick={() => navigate('/')}>{i18n.home}</span>/
            <span className='self'> {title}</span>
          </div>
          <ShopProducts data={data} from={DICTIONARY_CATEGORY} />
        </>
      )}
    </Wrapper>
  )
}

export default CategoryProducts
