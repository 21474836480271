import styled from 'styled-components'

const StatusCell = ({ status, text }) => {
  return <Wrapper className={`${status}`}>{text}</Wrapper>
}

const Wrapper = styled.label`
  background: var(--grey-300);
  border-radius: 5px;
  padding: 5px 10px;
  color: var(--black);

  &.success {
    background: var(--green-dark);
    color: var(--white);
  }

  &.danger {
    background: var(--red-dark);
    color: var(--white);
  }

  &.warning {
    background: var(--yellow-dark);
    color: var(--white);
  }
`

export default StatusCell
