import { useState } from 'react'
import styled from 'styled-components'
import { Button, Menu, MenuItem } from '@mui/material'
import { BsThreeDots } from 'react-icons/bs'

const MenuCell = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickItem = (callback) => {
    setAnchorEl(null)
    if (callback) {
      callback()
    }
  }

  return (
    <Wrapper>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <BsThreeDots className='dots-icon' />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items.map((item, index) => {
          return (
            <MenuItem key={index} onClick={() => handleClickItem(item.onClick)}>
              {item.text}
            </MenuItem>
          )
        })}
      </Menu>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .dots-icon {
    color: var(--black);
    font-size: var(--f-size-400);
  }
`

export default MenuCell
