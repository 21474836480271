import moment from 'moment'

const objToQuery = (obj) => {
  const keys = Object.keys(obj)

  let queryString = ''

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = obj[key]

    if (i === 0) {
      queryString += '?'
    } else {
      queryString += '&'
    }
    if (value) {
      queryString += `${key}=${value}`
    }
  }
  return queryString
}

const convertMsToTime = (milliseconds) => {
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0')
  }

  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)

  seconds = seconds % 60
  minutes = minutes % 60

  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
}

const convertDateByLocal = ({ date, format }) => {
  const utcDateTime = moment.utc(date)
  const localDateTime = utcDateTime.local()

  if (format) {
    return localDateTime.format(format)
  }

  return localDateTime.format('YYYY-MM-DD')
}

const calcUserInventoryLength = ({ inventory }) => {
  let lng = 0
  for (let i = 0; i < inventory.length; i++) {
    lng += inventory[i].quantity
  }

  return lng
}

const formatAmount = ({ amount, type }) => {
  if (!amount) {
    return ''
  }

  let formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount)

  if (type === 'MN') {
    formattedAmount = new Intl.NumberFormat('mn-MN', {
      style: 'currency',
      currency: 'MNT',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount)
  }

  return formattedAmount
}

const formatDate = ({ date }) => {
  const utcDateTime = moment.utc(date)
  const localDateTime = utcDateTime.local()

  return localDateTime.format('YYYY-MM-DD HH:mm')
}

export {
  objToQuery,
  convertMsToTime,
  convertDateByLocal,
  calcUserInventoryLength,
  formatAmount,
  formatDate,
}
