import { Outlet, ScrollRestoration } from 'react-router-dom'
import Wrapper from '../../wrappers/ShopLayout'
import ShopMenu from '../Shop/ShopMenu'
import ShopNavBar from '../Shop/ShopNavBar'
import Footer from '../Footer'
import Search from '../Shop/Search'

const ShopLayout = () => {
  return (
    <Wrapper>
      <ShopNavBar />
      <Search />
      <ShopMenu />
      <main className='main'>
        <Outlet />
        <Footer />
      </main>
      <ScrollRestoration />
    </Wrapper>
  )
}

export default ShopLayout
