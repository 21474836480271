import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAppContext } from '../../context/appContext'

const ShopProducts = ({ data, from }) => {
  const navigate = useNavigate()
  const { i18n } = useAppContext()

  return (
    <Wrapper>
      {data.map((item, index) => {
        const soldOut = item.inventory.quantity === 0 ? true : false

        return (
          <div
            className='item'
            key={index}
            onClick={() =>
              navigate(`/product/${item.id}`, { state: { id: item.id, from } })
            }
          >
            <img src={item.cover} alt={item.product_num} />
            {soldOut && <div className='sold-out'>{i18n.soldOut}</div>}
          </div>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  text-align: center;

  .item {
    position: relative;
    display: inline-block;
    width: 300px;
    height: 300px;
    overflow: hidden;
    cursor: pointer;
    margin: 10px;
    border: solid 1px var(--grey-200);
    &:hover img {
      transform: scale(1.1);
    }
  }

  .sold-out {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: var(--f-size-100);
    background: var(--primary-500);
    padding: 1px 7px;
    color: var(--white);
  }

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    object-fit: cover;
  }

  @media (max-width: 670px) {
    .item {
      height: fit-content;
      width: 100%;
      margin-right: 0;
      margin-left: 0;

      img {
        transform: scale(1.1);
      }
    }
  }
`

export default ShopProducts
