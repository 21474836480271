import {
  Paper,
  Typography,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Button,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppContext } from '../../../context/appContext'
import Wrapper from '../../../wrappers/Form'
import { AlertBox, FormUploadImage, Loading } from '../../../components'
import { useValidate } from '../../../hooks'
import {
  DB_ROW_ACTIVE,
  DB_ROW_INACTIVE,
  DICTIONARY_CATEGORY,
  DICTIONARY_COLLECTION,
  FILE_TYPE_FILE,
  VPatterns,
} from '../../../utils/constants'

const Product = () => {
  const { id } = useParams()
  const [isEditing] = useState(id !== 'add')
  const { authFetch, dictionary } = useAppContext()
  const [initLoading, setInitLoading] = useState(isEditing)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    product_num: '',
    description_en: '',
    description_mn: '',
    category_id: '',
    collection_id: '',
    details_mn: {},
    details_en: {},
    size: '',
    cover: '',
    inventory: {
      price: 0,
      quantity: 0,
    },
    updateInventory: false,
    isActive: DB_ROW_ACTIVE,
  })
  const navigate = useNavigate()
  const [cover, setCover] = useState()
  const [alertOpen, setAlertOpen] = useState({
    open: false,
  })
  const [categoryList] = useState(dictionary[DICTIONARY_CATEGORY])
  const [collectionList] = useState(dictionary[DICTIONARY_COLLECTION])

  const fetchData = async () => {
    try {
      setLoading(true)
      const { data } = await authFetch(`/products/${id}`)
      data.product.updateInventory = false
      setFormData(data.product)
      setInitLoading(false)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
    }
  }

  const fetchSave = async () => {
    try {
      setLoading(true)

      const imageFormData = new FormData()
      imageFormData.append('uploads', cover)
      imageFormData.append('type', FILE_TYPE_FILE)

      if (cover) {
        const { data } = await authFetch.post('/files', imageFormData)
        changeFormState({
          name: 'cover',
          value: `/uploads/${data.file.server_name}`,
        })
        formData.cover = `/uploads/${data.file.server_name}`
        setCover(null)
      }

      const endpoint = isEditing ? `/products/${id}` : `/products`
      const currentFetch = isEditing ? authFetch.patch : authFetch.post

      const { data } = await currentFetch(endpoint, {
        ...formData,
      })
      data.product.updateInventory = false

      setFormData(data.product)
      setLoading(false)
      setAlertOpen({ open: true, type: 'success', text: 'Collection updated' })
      if (!isEditing) {
        navigate(-1)
      }
    } catch (ex) {
      console.log(ex)
      setLoading(false)
      setAlertOpen({
        open: true,
        type: 'error',
        text: 'Server error please try again',
      })
    }
  }

  const changeFormState = ({ name, value }) => {
    setFormData({ ...formData, [name]: value })
  }

  const changeInventoryState = ({ name, value }) => {
    setFormData({
      ...formData,
      inventory: { ...formData.inventory, [name]: value },
    })
  }

  const changeDetailsItem = ({ keyVal, oldName, name, value }) => {
    const oldValue = formData[keyVal][oldName]
    delete formData[keyVal][oldName]
    formData[keyVal][name !== null && name !== undefined ? name : oldName] =
      value !== null && value !== undefined ? value : oldValue

    setFormData({ ...formData })
  }

  const removeDetailsItem = ({ keyVal, name }) => {
    delete formData[keyVal][name]
    setFormData({ ...formData })
  }

  const { handleChange, handleSubmit, errors } = useValidate({
    items: {
      product_num: {
        value: formData.product_num,
        onChange: changeFormState,
        required: true,
      },
      description_en: {
        value: formData.description_en,
        onChange: changeFormState,
      },
      description_mn: {
        value: formData.description_mn,
        onChange: changeFormState,
      },
      category_id: {
        value: formData.category_id,
        onChange: changeFormState,
        required: true,
      },
      collection_id: {
        value: formData.collection_id,
        onChange: changeFormState,
      },
      details_mn: {
        value: formData.details_mn,
        onChange: changeFormState,
      },
      details_en: {
        value: formData.details_en,
        onChange: changeFormState,
      },
      size: {
        value: formData.size,
        onChange: changeFormState,
      },
      cover: {
        value: formData.cover,
        onChange: changeFormState,
        required: true,
      },
      isActive: {
        value: formData.isActive,
        onChange: changeFormState,
        required: true,
      },
      updateInventory: {
        value: formData.updateInventory,
        onChange: changeFormState,
      },
      price: {
        value: formData.inventory.price,
        onChange: changeInventoryState,
        pattern: VPatterns.number,
      },
      quantity: {
        value: formData.inventory.quantity,
        onChange: changeInventoryState,
        pattern: VPatterns.number,
      },
    },
    nested: true,
  })

  useEffect(() => {
    if (isEditing) {
      fetchData()
    }

    // eslint-disable-next-line
  }, [isEditing])

  return (
    <Wrapper className={`${initLoading && 'loading'}`}>
      {!initLoading ? (
        <Paper sx={{ width: '100%' }} className={'paper'}>
          <Typography variant='h6' gutterBottom>
            Product
          </Typography>
          <FormUploadImage
            name='cover'
            label='Photo*'
            value={formData.cover}
            onChange={handleChange}
            error={errors.cover ? true : false}
            image={cover}
            setImage={setCover}
          />
          <Box
            component={'form'}
            sx={{
              '& > :not(style)': { m: 1, width: '280px' },
            }}
            mt={'20px'}
          >
            <TextField
              name='product_num'
              label='product number*'
              variant='outlined'
              value={formData?.product_num}
              onChange={handleChange}
              error={errors.product_num ? true : false}
            />
            <FormControl className='form-field'>
              <InputLabel>category*</InputLabel>
              <Select
                name='category_id'
                label='category*'
                value={formData.category_id}
                onChange={handleChange}
                error={errors.category_id ? true : false}
              >
                <MenuItem value={''}>Select</MenuItem>
                {categoryList.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name_en}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl className='form-field'>
              <InputLabel>collection</InputLabel>
              <Select
                name='collection_id'
                label='collection'
                value={formData.collection_id}
                onChange={handleChange}
              >
                <MenuItem value={''}>Select</MenuItem>
                {collectionList.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name_en}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <TextField
              name='size'
              label='size'
              variant='outlined'
              value={formData?.size}
              onChange={handleChange}
              error={errors.size ? true : false}
            />
            <TextField
              name='price'
              label='price*'
              variant='outlined'
              value={formData?.inventory.price}
              onChange={handleChange}
              error={errors.price ? true : false}
            />
            <TextField
              name='quantity'
              label='quantity*'
              variant='outlined'
              value={formData?.inventory.quantity}
              onChange={handleChange}
              error={errors.quantity ? true : false}
            />
          </Box>
          <Box
            component={'form'}
            sx={{
              '& > :not(style)': { m: 1, width: '280px' },
            }}
          >
            <TextField
              name='description_en'
              label='description en'
              variant='outlined'
              value={formData.description_en}
              onChange={handleChange}
              error={errors.description_en ? true : false}
              multiline
              rows={5}
            />
            <TextField
              name='description_mn'
              label='description mn'
              variant='outlined'
              value={formData.description_mn}
              onChange={handleChange}
              error={errors.description_mn ? true : false}
              multiline
              rows={5}
            />
          </Box>
          {/* *** DETAILS ENGLISH */}
          <Box
            component={'form'}
            padding={'20px'}
            margin={'20px 8px'}
            border={'solid 1px var(--grey-300)'}
            borderRadius={'5px'}
          >
            <Stack
              spacing={2}
              direction='row'
              alignItems={'center'}
              justifyContent={'flex-start'}
              padding={'10px'}
            >
              <Typography variant='h6' gutterBottom>
                Details English
              </Typography>
              <Button
                variant='text'
                onClick={() =>
                  changeDetailsItem({
                    keyVal: 'details_en',
                    oldName: 'add',
                    name: 'add',
                    value: '',
                  })
                }
              >
                add detail
              </Button>
            </Stack>
            {Object.keys(formData.details_en).map((key, index) => {
              const itemValue = formData.details_en[key]
              const keyVal = 'details_en'
              const oldName = key
              return (
                <Stack
                  spacing={2}
                  direction='row'
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  mt={'10px'}
                  key={index}
                >
                  <TextField
                    label='name en'
                    variant='outlined'
                    size={'small'}
                    value={key !== 'add' ? key : ''}
                    onChange={(e) =>
                      changeDetailsItem({
                        keyVal,
                        oldName,
                        name: e.target.value,
                      })
                    }
                  />
                  <TextField
                    label='value en'
                    variant='outlined'
                    size={'small'}
                    value={itemValue}
                    onChange={(e) =>
                      changeDetailsItem({
                        keyVal,
                        oldName,
                        value: e.target.value,
                      })
                    }
                  />
                  <Button
                    variant='text'
                    onClick={() => removeDetailsItem({ keyVal, name: oldName })}
                  >
                    remove
                  </Button>
                </Stack>
              )
            })}
          </Box>
          {/* DETAILS Mongolian */}
          <Box
            component={'form'}
            padding={'20px'}
            margin={'20px 8px'}
            border={'solid 1px var(--grey-300)'}
            borderRadius={'5px'}
          >
            <Stack
              spacing={2}
              direction='row'
              alignItems={'center'}
              justifyContent={'flex-start'}
              padding={'10px'}
            >
              <Typography variant='h6' gutterBottom>
                Details Mongolian
              </Typography>
              <Button
                variant='text'
                onClick={() =>
                  changeDetailsItem({
                    keyVal: 'details_mn',
                    oldName: 'add',
                    name: 'add',
                    value: '',
                  })
                }
              >
                add detail
              </Button>
            </Stack>
            {Object.keys(formData.details_mn).map((key, index) => {
              const itemValue = formData.details_mn[key]
              const keyVal = 'details_mn'
              const oldName = key
              return (
                <Stack
                  spacing={2}
                  direction='row'
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  mt={'10px'}
                  key={index}
                >
                  <TextField
                    label='name en'
                    variant='outlined'
                    size={'small'}
                    value={key !== 'add' ? key : ''}
                    onChange={(e) =>
                      changeDetailsItem({
                        keyVal,
                        oldName,
                        name: e.target.value,
                      })
                    }
                  />
                  <TextField
                    label='value en'
                    variant='outlined'
                    size={'small'}
                    value={itemValue}
                    onChange={(e) =>
                      changeDetailsItem({
                        keyVal,
                        oldName,
                        value: e.target.value,
                      })
                    }
                  />
                  <Button
                    variant='text'
                    onClick={() => removeDetailsItem({ keyVal, name: oldName })}
                  >
                    remove
                  </Button>
                </Stack>
              )
            })}
          </Box>
          <Box
            component={'form'}
            sx={{
              '& > :not(style)': { m: 1, width: '280px' },
            }}
          >
            <FormControl>
              <InputLabel>status</InputLabel>
              <Select
                name='isActive'
                label='status'
                value={formData.isActive}
                onChange={handleChange}
              >
                <MenuItem value={DB_ROW_ACTIVE}>Active</MenuItem>
                <MenuItem value={DB_ROW_INACTIVE}>Inactive</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>update inventory</InputLabel>
              <Select
                name='updateInventory'
                label='update inventory'
                value={formData.updateInventory}
                onChange={handleChange}
              >
                <MenuItem value={false}>Do not Update</MenuItem>
                <MenuItem value={true}>Do Update</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Stack
            spacing={2}
            direction='row'
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <AlertBox
              open={alertOpen.open}
              setOpen={(open) => setAlertOpen({ open })}
              type={alertOpen.type}
              text={alertOpen.text}
            />
            <LoadingButton variant='text' onClick={() => navigate(-1)}>
              Back
            </LoadingButton>
            <LoadingButton
              variant='contained'
              loading={loading}
              disabled={loading}
              onClick={(e) => handleSubmit(e, fetchSave)}
            >
              {isEditing ? 'Edit' : 'Add'}
            </LoadingButton>
          </Stack>
        </Paper>
      ) : (
        <Loading color={'black'} />
      )}
    </Wrapper>
  )
}

export default Product
