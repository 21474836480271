import styled from 'styled-components'
import { Box, TextField, Button } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { useEffect } from 'react'

const FormUploadImage = ({
  name,
  label,
  value,
  onChange,
  error,
  setImage,
  image,
}) => {
  const { acceptedFiles, getInputProps, open } = useDropzone({
    maxFiles: 1,
    accept: { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] },
  })

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setImage(acceptedFiles[0])
  
      const file = acceptedFiles[0]
      const reader = new FileReader()
      reader.onloadend = () => {
        onChange({
          target: {
            name,
            value: reader.result, // base64 encoded string
          },
        })
      }
      reader.readAsDataURL(file)
    }

    // eslint-disable-next-line
  }, [acceptedFiles])

  return (
    <Wrapper>
      <Box component={'form'} mb={'20px'} display={'flex'}>
        <input {...getInputProps()} />
        <TextField
          name={name}
          label={label}
          variant='outlined'
          value={value}
          onChange={onChange}
          error={error ? true : false}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
        <Button variant='outlined' onClick={() => open()}>
          Select
        </Button>
      </Box>
      <div className='preview'>
        <img src={value} alt='cover' />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .preview {
    text-align: center;

    img {
      max-height: 200px;
    }
  }
`

export default FormUploadImage
