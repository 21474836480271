import styled from 'styled-components'
import { useAppContext } from '../../context/appContext'
import { DICTIONARY_CATEGORY } from '../../utils/constants'
import { TfiClose } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'

const ShopMenu = () => {
  const {
    dictionary,
    toggleMenu,
    showMenu,
    logoutUser,
    lang,
    changeLanguage,
    i18n,
  } = useAppContext()
  const navigate = useNavigate()

  const handleClick = ({ path, id }) => {
    navigate(path, { state: { id } })
    toggleMenu()
  }

  return (
    showMenu && (
      <Wrapper>
        <div className='close-icon'>
          <TfiClose onClick={toggleMenu} />
        </div>
        <div className='menus'>
          <div
            className='menu collection'
            onClick={() => handleClick({ path: '/collections' })}
          >
            {i18n.collections}
          </div>
          {dictionary[DICTIONARY_CATEGORY].map((item, index) => {
            return (
              <div
                key={index}
                className='menu category'
                onClick={() =>
                  handleClick({ path: `/category/${item.id}`, id: item.id })
                }
              >
                {lang === 'ENG' ? item.name_en : item.name_mn}
              </div>
            )
          })}
          {/* <div
            className='menu profile'
            onClick={() => handleClick({ path: '/profile' })}
          >
            {i18n.yourProfile}
          </div>
          <div className='menu profile' onClick={logoutUser}>
            {i18n.logout}
          </div> */}
        </div>
        <div className='menu non-border'>
          <span
            className={`${lang === 'ENG' ? 'selected' : ''}`}
            onClick={() => changeLanguage({ lang: 'ENG' })}
          >
            Eng
          </span>{' '}
          |{' '}
          <span
            className={`${lang === 'MN' ? 'selected' : ''}`}
            onClick={() => changeLanguage({ lang: 'MN' })}
          >
            Mon
          </span>
        </div>
      </Wrapper>
    )
  )
}

const Wrapper = styled.div`
  width: 400px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: var(--primary-500);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 28px;
  z-index: 99;

  .close-icon {
    align-self: flex-end;
    color: var(--white);
    cursor: pointer;
  }

  .menus {
    width: 200px;
  }

  .menu {
    font-size: var(--f-size-100);
    padding-bottom: 20px;
    border: none;
    border-bottom: solid 1px var(--white);
    color: var(--white);
    text-align: center;
    cursor: pointer;
  }

  .non-border {
    border-bottom: none;
  }

  .collection {
    margin-bottom: 72px;
  }

  .profile {
    margin-top: 20px;
    margin-bottom: 21px;
  }

  .category {
    margin-bottom: 21px;
  }

  .selected {
    font-weight: 700;
  }

  @media (max-width: 582px) {
    width: 100%;
  }
`

export default ShopMenu
