import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;

  main {
    margin-top: var(--shop-nav-height);
  }
`

export default Wrapper
