import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Loading, ShopProducts } from '../../components'
import { useAppContext } from '../../context/appContext'
import { objToQuery } from '../../utils'
import {
  DB_ROW_ACTIVE,
  DICTIONARY_CATEGORY,
  SHOP_PRODUCT_LIMIT,
} from '../../utils/constants'
import Wrapper from '../../wrappers/Product'

const ShopSearch = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { authFetch, dataTables, i18n } = useAppContext()
  const navigate = useNavigate()
  const location = useLocation()

  const fetchData = async () => {
    try {
      setLoading(true)

      const queryObj = {
        limit: SHOP_PRODUCT_LIMIT,
        page: 1,
        isActive: DB_ROW_ACTIVE,
        search: dataTables.mainSearch.search,
      }

      if (dataTables.mainSearch.price === '<500') {
        queryObj.maxPrice = 500
      } else if (dataTables.mainSearch.price === '500-1000') {
        queryObj.minPrice = 500
        queryObj.maxPrice = 1000
      } else if (dataTables.mainSearch.price === '1000-2000') {
        queryObj.minPrice = 1000
        queryObj.maxPrice = 2000
      } else if (dataTables.mainSearch.price === '2000-3000') {
        queryObj.minPrice = 2000
        queryObj.maxPrice = 3000
      } else if (dataTables.mainSearch.price === '3000-4000') {
        queryObj.minPrice = 3000
        queryObj.maxPrice = 4000
      } else if (dataTables.mainSearch.price === '4000-5000') {
        queryObj.minPrice = 4000
        queryObj.maxPrice = 5000
      } else if (dataTables.mainSearch.price === '5000<') {
        queryObj.minPrice = 5000
      }

      const { data: resData } = await authFetch.get(
        `/products${objToQuery(queryObj)}`
      )

      setData(resData.rows)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
      console.log(ex)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [location])

  return (
    <Wrapper>
      {loading ? (
        <div className='loading-container'>
          <Loading color={'black'} />
        </div>
      ) : (
        <>
          <div className='title'>{i18n.search}</div>
          <div className='route-map'>
            <span onClick={() => navigate('/')}>{i18n.home}</span>/
            <span>{i18n.search}</span>
          </div>
          <ShopProducts data={data} from={DICTIONARY_CATEGORY} />
        </>
      )}
    </Wrapper>
  )
}

export default ShopSearch
