import React, { useState } from 'react'
import Wrapper from '../../wrappers/Login'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { Button, Loading } from '../../components'
import { useAppContext } from '../../context/appContext'
import { objToQuery, convertMsToTime } from '../../utils'
import { useScreen } from '../../hooks'

const Verify = () => {
  const { fetch, authFetch, user, logoutUser, delay, i18n } = useAppContext()
  const { code } = useParams()
  const [loading, setLoading] = useState(code ? true : false)
  const [verified, setVerified] = useState(false)
  const [timer, setTimer] = useState(0)
  const navigate = useNavigate()

  const fetchVerify = async () => {
    try {
      setLoading(true)
      await fetch.get(`/auth/verify${objToQuery({ key: code })}`)
      setVerified(true)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
      console.log(ex)
    }
  }

  const resendEmail = async () => {
    try {
      setLoading(true)
      const { data } = await authFetch.post('/auth/verify')
      const serverDelay = data.delay * 1000

      if (serverDelay < delay) {
        setTimer(delay - serverDelay)
      }

      if (data.sent) {
        setTimer(delay)
      }

      setLoading(false)
    } catch (ex) {
      setLoading(false)
      console.log(ex)
    }
  }

  const loginAgain = () => {
    logoutUser()
    navigate('/auth')
  }

  useEffect(() => {
    if (code) {
      fetchVerify()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!user && !code) {
      navigate('/auth')
    }

    if (user && verified) {
      navigate('/')
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    const countDown =
      timer > 0 && setInterval(() => setTimer(timer - 1000), 1000)

    return () => {
      clearInterval(countDown)
    }
  }, [timer])

  return (
    <Wrapper>
      {!code && (
        <p className='full-link'>
          {i18n.verifyMsg}
          <br />
          {loading ? (
            <Loader />
          ) : timer <= 0 ? (
            <span className='bold' onClick={resendEmail}>
              {i18n.resend}
            </span>
          ) : (
            <span className='bold'>{convertMsToTime(timer)}</span>
          )}
        </p>
      )}

      {code && loading && <Loader />}
      {code && !loading && !verified && <Loader /> && (
        <p className='full-link'>{i18n.wrongVerifyLink}</p>
      )}
      {code && !loading && verified && (
        <p className='full-link'>
          {i18n.verified}
          <Link className='bold' to='/auth/login'></Link>
        </p>
      )}

      <div className='logoutBtn'>
        <Button text={i18n.login} onClick={loginAgain} />
      </div>
    </Wrapper>
  )
}

const Loader = () => {
  const { isWide } = useScreen()

  return (
    <p className='full-link'>
      {isWide ? <Loading color={'black'} /> : <Loading />}
    </p>
  )
}

export default Verify
