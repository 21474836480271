import styled from 'styled-components'
import { useAppContext } from '../../context/appContext'
import { DICTIONARY_COLLECTION } from '../../utils/constants'
import { useNavigate } from 'react-router-dom'
import collectionBanner from '../../assets/images/collections-banner.png'
import { ShopCollections } from '../../components'

const Collections = () => {
  const { dictionary } = useAppContext()
  const navigate = useNavigate()

  return (
    <Wrapper>
      <div className='collections-banner'>
        <img src={collectionBanner} alt='collections banner' />
      </div>
      <ShopCollections
        data={dictionary[DICTIONARY_COLLECTION]}
        onMore={() => navigate('/collections')}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .collections-banner {
    width: 100%;

    img {
      width: 100%;
    }
  }
`

export default Collections
