import { useState } from 'react'
import styled from 'styled-components'

const FormCheckBox = ({ name, text, value, onChange, textOnClick }) => {
  const [checked, setChecked] = useState(value ? value : false)

  const handleChange = (e) => {
    const event = e
    event.target.value = !checked
    onChange(event)
    setChecked(!checked)
  }

  return (
    <Wrapper>
      <span onClick={textOnClick}>{text}</span>
      <input
        name={name}
        type='checkbox'
        value={checked}
        onChange={(e) => handleChange(e)}
      />
      <span className='checkMark'></span>
    </Wrapper>
  )
}

const Wrapper = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: var(--f-size-100);

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkMark {
      background: var(--gold);
    }

    &:checked ~ .checkMark:after {
      display: block;
    }
  }

  .checkMark {
    background: var(--white);
    position: absolute;
    top: 4px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 8px;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 4px;
      height: 8px;
      border: solid var(--black);
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  @media (max-width: 1024px) {
    color: var(--white);
  }
`

export default FormCheckBox
