import React, { useState } from 'react'
import { Alert, Button, FormCheckBox, FormRow } from '../../components'
import Wrapper from '../../wrappers/Login'
import { VPatterns } from '../../utils/constants'
import { useValidate } from '../../hooks'
import { Link, useNavigate } from 'react-router-dom'
import { useAppContext } from '../../context/appContext'
import { useEffect } from 'react'

const Register = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [privacyAgree, setPrivacyAgree] = useState(false)
  const [invitationCode, setInvitationCode] = useState('')
  const [passwordNotMatch, setPasswordNotMatch] = useState(false)
  const { setupUser, isLoading, user, i18n } = useAppContext()
  const navigate = useNavigate()

  const { handleChange, handleSubmit, errors } = useValidate({
    items: {
      name: {
        value: name,
        onChange: setName,
        required: true,
      },
      email: {
        value: email,
        pattern: VPatterns.email,
        onChange: setEmail,
        required: true,
      },
      phone: {
        value: phone,
        onChange: setPhone,
        required: true,
        minLength: 8,
        maxLength: 8,
      },
      password: {
        value: password,
        onChange: setPassword,
        required: true,
        minLength: 6,
      },
      passwordRepeat: {
        value: passwordRepeat,
        onChange: setPasswordRepeat,
        required: true,
      },
      privacyAgree: {
        value: privacyAgree,
        onChange: setPrivacyAgree,
        required: true,
      },
      invitationCode: {
        value: invitationCode,
        onChange: setInvitationCode,
        required: true,
      },
    },
  })

  const register = async () => {
    if (password !== passwordRepeat) {
      setPasswordNotMatch(true)
      return false
    } else {
      setPasswordNotMatch(false)
    }

    await setupUser({
      currentUser: {
        name,
        email,
        phone,
        password,
        invitationCode,
      },
      endPoint: 'register',
    })
  }

  useEffect(() => {
    if (user) {
      navigate('/')
    }

    // eslint-disable-next-line
  }, [user])

  return (
    <Wrapper>
      <FormRow
        type={'text'}
        name={'name'}
        value={name}
        handleChange={handleChange}
        labelText={i18n.name}
        hideLabel={true}
      />
      <FormRow
        type={'text'}
        name={'email'}
        value={email}
        handleChange={handleChange}
        labelText={i18n.email}
        hideLabel={true}
      />
      <FormRow
        type={'text'}
        name={'phone'}
        value={phone}
        handleChange={handleChange}
        labelText={i18n.phone}
        hideLabel={true}
      />
      <FormRow
        type={'password'}
        name={'password'}
        value={password}
        handleChange={handleChange}
        labelText={i18n.password}
        hideLabel={true}
      />
      <FormRow
        type={'password'}
        name={'passwordRepeat'}
        value={passwordRepeat}
        handleChange={handleChange}
        labelText={i18n.repeatPassword}
        hideLabel={true}
      />
      <div className='thin'>
        <FormCheckBox
          text={i18n.terms}
          name='privacyAgree'
          value={privacyAgree}
          onChange={handleChange}
          textOnClick={() => window.open('/privacy.pdf')}
        />
      </div>

      <FormRow
        type={'text'}
        name={'invitationCode'}
        value={invitationCode}
        handleChange={handleChange}
        labelText={i18n.invitationCode}
        hideLabel={true}
      />

      {Object.keys(errors).length > 0 && (
        <p className='error'>{i18n.provideAllValues}</p>
      )}
      {passwordNotMatch && <p className='error'>{i18n.passwordDoesNotMatch}</p>}
      <Alert />
      <div className='buttons'>
        <Button
          text={'Register'}
          onClick={(e) => handleSubmit(e, register)}
          loading={isLoading}
        />
      </div>
      <p className='full-link'>
        {i18n.alreadyHaveAnAccount}
        <Link to={'/auth'} className={'bold'}>
          {' '}
          {i18n.login}
        </Link>
      </p>
    </Wrapper>
  )
}

export default Register
