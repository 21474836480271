import {
  Paper,
  Typography,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppContext } from '../../../context/appContext'
import Wrapper from '../../../wrappers/Form'
import { AlertBox, FormUploadImage, Loading } from '../../../components'
import { useValidate } from '../../../hooks'
import {
  DB_ROW_ACTIVE,
  DB_ROW_INACTIVE,
  FILE_TYPE_FILE,
} from '../../../utils/constants'

const Category = () => {
  const { id } = useParams()
  const [isEditing] = useState(id !== 'add')
  const { authFetch } = useAppContext()
  const [initLoading, setInitLoading] = useState(isEditing)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name_mn: '',
    name_en: '',
    cover: '',
    isActive: DB_ROW_ACTIVE,
  })
  const navigate = useNavigate()
  const [cover, setCover] = useState()
  const [alertOpen, setAlertOpen] = useState({
    open: false,
  })

  const fetchData = async () => {
    try {
      setLoading(true)
      const { data } = await authFetch(`/category/${id}`)

      setFormData(data.category)
      setInitLoading(false)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
    }
  }

  const fetchSave = async () => {
    try {
      setLoading(true)

      const imageFormData = new FormData()
      imageFormData.append('uploads', cover)
      imageFormData.append('type', FILE_TYPE_FILE)

      if (cover) {
        const { data } = await authFetch.post('/files', imageFormData)
        changeFormState({
          name: 'cover',
          value: `/uploads/${data.file.server_name}`,
        })
        formData.cover = `/uploads/${data.file.server_name}`
        setCover(null)
      }

      const endpoint = isEditing ? `/category/${id}` : `/category`
      const currentFetch = isEditing ? authFetch.patch : authFetch.post

      const { data } = await currentFetch(endpoint, {
        ...formData,
      })

      setFormData(data.category)
      setLoading(false)
      setAlertOpen({ open: true, type: 'success', text: 'Category updated' })
      if (!isEditing) {
        navigate(-1)
      }
    } catch (ex) {
      setLoading(false)
      setAlertOpen({
        open: true,
        type: 'error',
        text: 'Server error please try again',
      })
    }
  }

  const changeFormState = ({ name, value }) => {
    setFormData({ ...formData, [name]: value })
  }

  const { handleChange, handleSubmit, errors } = useValidate({
    items: {
      name_mn: {
        value: formData.name_mn,
        onChange: changeFormState,
        required: true,
      },
      name_en: {
        value: formData.name_en,
        onChange: changeFormState,
        required: true,
      },
      cover: {
        value: formData.cover,
        onChange: changeFormState,
        required: true,
      },
      isActive: {
        value: formData.isActive,
        onChange: changeFormState,
        required: true,
      },
    },
    nested: true,
  })

  useEffect(() => {
    if (isEditing) {
      fetchData()
    }

    // eslint-disable-next-line
  }, [isEditing])

  return (
    <Wrapper className={`${initLoading && 'loading'}`}>
      {!initLoading ? (
        <Paper sx={{ width: '100%' }} className={'paper'}>
          <Typography variant='h6' gutterBottom>
            Category
          </Typography>

          <FormUploadImage
            name='cover'
            label='Photo*'
            value={formData.cover}
            onChange={handleChange}
            error={errors.cover ? true : false}
            image={cover}
            setImage={setCover}
          />
          <Box
            component={'form'}
            sx={{
              '& > :not(style)': { m: 1, width: '280px' },
            }}
            mt={'20px'}
          >
            <TextField
              name='name_mn'
              label='name mn*'
              variant='outlined'
              value={formData?.name_mn}
              onChange={handleChange}
              error={errors.name_mn ? true : false}
            />
            <TextField
              name='name_en'
              label='name en*'
              variant='outlined'
              value={formData.name_en}
              onChange={handleChange}
              error={errors.name_en ? true : false}
            />
            <FormControl>
              <InputLabel>status</InputLabel>
              <Select
                name='isActive'
                label='status'
                value={formData.isActive}
                onChange={handleChange}
              >
                <MenuItem value={DB_ROW_ACTIVE}>Active</MenuItem>
                <MenuItem value={DB_ROW_INACTIVE}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Stack
            spacing={2}
            direction='row'
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <AlertBox
              open={alertOpen.open}
              setOpen={(open) => setAlertOpen({ open })}
              type={alertOpen.type}
              text={alertOpen.text}
            />
            <LoadingButton variant='text' onClick={() => navigate(-1)}>
              Back
            </LoadingButton>
            <LoadingButton
              variant='contained'
              loading={loading}
              disabled={loading}
              onClick={(e) => handleSubmit(e, fetchSave)}
            >
              {isEditing ? 'Edit' : 'Add'}
            </LoadingButton>
          </Stack>
        </Paper>
      ) : (
        <Loading color={'black'} />
      )}
    </Wrapper>
  )
}

export default Category
