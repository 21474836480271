import React, { useState } from 'react'
import { Button, FormRow } from '../../components'
import Wrapper from '../../wrappers/Login'
import { VPatterns } from '../../utils/constants'
import { useValidate } from '../../hooks'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useAppContext } from '../../context/appContext'
import { convertMsToTime } from '../../utils'

const SendResetPassword = () => {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const { user, fetch, delay, i18n } = useAppContext()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const [timer, setTimer] = useState(0)

  const { handleChange, handleSubmit, errors } = useValidate({
    items: {
      email: {
        value: email,
        pattern: VPatterns.email,
        onChange: setEmail,
        ignoreOn: true,
        required: true,
      },
    },
  })

  const send = async () => {
    try {
      setLoading(true)
      const { data } = await fetch.post('/auth/reset', { email })
      setSent(data.sent)
      const serverDelay = data.delay * 1000

      if (serverDelay < delay) {
        setTimer(delay - serverDelay)
      }

      if (data.sent) {
        setTimer(delay)
      }

      setError(null)
      setLoading(false)
    } catch (ex) {
      setLoading(false)

      if (ex.response.status === 404) {
        setError(i18n.notEmailRegistered)
      }
    }
  }

  useEffect(() => {
    if (user) {
      navigate('/')
    }

    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    const countDown =
      timer > 0 && setInterval(() => setTimer(timer - 1000), 1000)

    return () => {
      clearInterval(countDown)
    }
  }, [timer])

  useEffect(() => {
    setError(null)
  }, [email])

  return (
    <Wrapper>
      {timer > 0 || sent ? (
        <p className='full-link'>
          {i18n.resetMsg}
          <br />
          <span className='bold'>{convertMsToTime(timer)}</span>
        </p>
      ) : (
        <>
          <FormRow
            type={'text'}
            name={'email'}
            value={email}
            handleChange={handleChange}
            labelText={i18n.email}
            hideLabel={true}
          />
          {Object.keys(errors).length > 0 && (
            <p className='error'>{i18n.wrongEmail}</p>
          )}
          {error && <p className='error'>{error}</p>}
          <div className='buttons'>
            <Button
              text={i18n.send}
              onClick={(e) => handleSubmit(e, send)}
              loading={loading}
            />
          </div>
        </>
      )}
      <p className='full-link'>
        Back to
        <Link to={'/auth'} className={'bold'}>
          {' '}
          {i18n.login}
        </Link>
      </p>
    </Wrapper>
  )
}

export default SendResetPassword
