import { Outlet } from 'react-router-dom'
import Wrapper from '../../wrappers/AuthLayout'
import Partners from '../Partners'

const AuthLayout = () => {
  return (
    <Wrapper>
      <div className='column left'>
        <div className='content'>
          <div className='texts'>
            <h1 className='site-title'>DIAMOND & JEWELLERY</h1>
            <h2 className='site-description'>PRIORITY EXHIBITION</h2>
          </div>
        </div>
        <div className='partners'>
          <Partners />
        </div>
      </div>
      <div className='column right'>
        <div className='content'>
          <div className='inner'>
            <div className='texts'>
              <h1 className='site-title'>DIAMOND & JEWELLERY</h1>
              <h2 className='site-description'>PRIORITY EXHIBITION</h2>
            </div>
            <Outlet />
          </div>
        </div>
        <div className='partners'>
          <Partners />
        </div>
      </div>
    </Wrapper>
  )
}

export default AuthLayout
