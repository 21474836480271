import styled from 'styled-components'
import { BsCheck2Circle } from 'react-icons/bs'
import Portal from '../Portal'
import Button from '../Buttons/Button'
import { BiCommentError } from 'react-icons/bi'
import { useAppContext } from '../../context/appContext'

const SuccessModal = ({ show, setShow, callback, errors }) => {
  const { lang } = useAppContext()

  const isError = errors.length > 0 ? true : false

  const handleClose = () => {
    setShow(false)
    if (callback && !isError) {
      callback()
    }
  }

  return (
    show && (
      <Portal>
        <Wrapper>
          <div className={`wrapper ${isError ? 'error' : ''}`}>
            <div className='title'>
              {isError ? 'Not enough in Stock' : 'Succesfull'}
            </div>
            <div className='icon-cont'>
              {isError ? (
                <BiCommentError className='icon' />
              ) : (
                <BsCheck2Circle className='icon' />
              )}
            </div>
            {isError ? (
              <div className='message'>
                {lang === 'ENG'
                  ? 'Sorry not enough products to your order in stock. please change order and try again'
                  : 'Уучлаарай барааны үлдэгдэл хүрэлцэхгүй байна. бараагаа өөрчлөөд дахин оролдоно уу'}
              </div>
            ) : lang === 'ENG' ? (
              <div className='message'>
                Sincerely thank you for choosing us. The notification of order
                confirmation will be delivered to your registered email after 30
                minutes once the payment has been done. Note that payment should
                be done within 48 hours after your order is made and within this
                period of time, the order can be changed and canceled. In case
                of failure on confirming the payment, your order will be
                canceled automatically.
              </div>
            ) : (
              <div className='message'>
                Биднийг сонгон үйлчлүүлсэн эрхэм таньд баярлалаа. Худалдан
                авалтын төлбөр төлөгдсөнөөс 30 минутын дараа таны бүртгэлтэй
                имэйл хаягт захиалга баталгаажсан талаар мэдэгдэл хүргэх болно.
                <br />
                Захиалга хийгдсэнээс хойш 48 цагийн хугацаанд төлбөр гүйцэтгэх
                боломжийг олгож байгаа бөгөөд тус хугацаанд захиалгыг цуцлах,
                өөрчлөх боломжтой. Худалдан авалтын төлбөрөө баталгаажуулаагүй
                тохиолдолд таны захиалга автоматаар цуцлагдахыг анхаарна уу.
              </div>
            )}
            <div className='btn'>
              <Button text={'close'} color={'white'} onClick={handleClose} />
            </div>
          </div>
        </Wrapper>
      </Portal>
    )
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  top: 0;
  left: 0;
  padding: 20px;

  .wrapper {
    background: var(--white);
    padding: 30px;
    font-size: var(--f-size-100);
    color: var(--black);
    max-width: 480px;
    border-radius: 20px;
  }

  .error {
    .title {
      color: var(--red-light);
    }
    .icon {
      color: var(--red-light);
    }
  }

  .title {
    text-align: center;
    color: var(--gold);
    font-size: var(--f-size-200);
    margin-bottom: 10px;
  }

  .icon-cont {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .message {
    margin-bottom: 20px;
  }

  .icon {
    color: var(--gold);
    font-size: 90px;
  }

  .btn {
    text-align: center;

    button {
      width: 100px;
    }
  }
`

export default SuccessModal
