import Wrapper from '../../wrappers/Home'
import homeBanner from '../../assets/images/home-banner.png'
import {
  Partners,
  ShopAbout,
  ShopCategories,
  ShopCollections,
} from '../../components'
import { useAppContext } from '../../context/appContext'
import {
  DICTIONARY_CATEGORY,
  DICTIONARY_COLLECTION,
} from '../../utils/constants'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  const { dictionary, i18n } = useAppContext()
  const navigate = useNavigate()

  if(Object.keys(dictionary).length === 0){
    return <></>
  }

  return (
    <Wrapper>
      <div className='home-banner'>
        <img src={homeBanner} alt='home banner' />
      </div>
      <ShopCollections
        data={dictionary[DICTIONARY_COLLECTION]}
        short={true}
        onMore={() => navigate('/collections')}
      />
      <ShopCategories data={dictionary[DICTIONARY_CATEGORY]} />
      <ShopAbout />
      <div className='partners-home'>
        <div className='partners-home-title'>{i18n.partners}</div>
        <Partners color={'black'} />
      </div>
    </Wrapper>
  )
}

export default Home
