import styled from 'styled-components'

const Wrapper = styled.div`
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(90vh - var(--nav-height));
  }

  .paper {
    padding: 20px;
  }

  .detail-info {
    color: var(--grey-500);
    padding: 20px;
    font-size: var(--f-size-100);

    .bold {
      font-weight: bold;
      margin-right: 10px;
    }
  }
`

export default Wrapper
