import logoWhite from '../../assets/images/brinks-logo-white.png'
import logoBlack from '../../assets/images/brinks-logo-black.png'

const BrinksLogo = ({ color }) => {
  if (color === 'white') {
    return <img src={logoWhite} alt='Brinks' className='logo' />
  } else {
    return <img src={logoBlack} alt='Brinks' className='logo' />
  }
}

export default BrinksLogo
