import styled from 'styled-components'
import bg from '../assets/images/login-background.png'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100vh;

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    background: url(${bg});
    background-size: cover;
    padding: 25px;
  }

  .content {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .right {
    background: var(--grey-100);
    padding: 15px;

    .texts {
      display: none;
      margin-bottom: 10vh;
    }

    .partners {
      display: none;
    }
  }

  h1 {
    color: var(--gold);
    text-align: center;
    font-weight: 700;
  }
  h2 {
    color: var(--white);
    text-align: center;
    font-weight: 400;
  }

  .partners {
    width: 100%;
  }

  @media (max-width: 1024px) {
    .column {
      width: 100%;
    }
    .left {
      display: none;
    }
    .right {
      background: url(${bg});
      background-size: cover;

      .texts {
        display: block;
      }
      .partners {
        display: block;
      }
    }
  }

  @media (max-width: 768px) {
  }
`

export default Wrapper
