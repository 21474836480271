import styled from 'styled-components'

const Wrapper = styled.div`
  .home-banner {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .partners-home {
    margin-top: 56px;
  }

  .partners-home-title {
    font-size: var(--f-size-400);
    width: 100%;
    margin-top: 56px;
    text-align: center;
    color: var(--black);
  }
`

export default Wrapper
