import styled from 'styled-components'
import BrinksLogo from './Logo/BrinksLogo'
import DBLogo from './Logo/DBLogo'
import HraGroupLogo from './Logo/HraGroupLogo'
import Logo from './Logo/Logo'
import PriorityLogo from './Logo/PriorityLogo'

const Partners = ({ color }) => {
  return (
    <Wrapper>
      <div className='partners-logos'>
        <div className='partner-logo hra'>
          <HraGroupLogo color={`${color ? color : 'white'}`} />
        </div>
        <div className='partner-logo db'>
          <DBLogo color={`${color ? color : 'white'}`} />
        </div>
        <div className='partner-logo gemma'>
          <Logo color={`${color ? color : 'white'}`} />
        </div>
      </div>
      <div className='partners-logos'>
        <div className='partner-logo priority wide'>
          <PriorityLogo color={`${color ? color : 'white'}`} />
        </div>
        <div className='partner-logo priority wide'>
          <BrinksLogo color={`${color ? color : 'white'}`} />
        </div>
      </div>

      {/* mobile */}
      <div className='partners-logos mobile'>
        <div className='partner-logo hra'>
          <HraGroupLogo color={`${color ? color : 'white'}`} />
        </div>
        <div className='partner-logo db'>
          <DBLogo color={`${color ? color : 'white'}`} />
        </div>
      </div>
      <div className='partners-logos mobile'>
        <div className='partner-logo gemma'>
          <Logo color={`${color ? color : 'white'}`} />
        </div>
        <div className='partner-logo priority'>
          <PriorityLogo color={`${color ? color : 'white'}`} />
        </div>
      </div>
      <div className='partners-logos mobile'>
        <div className='partner-logo priority wide'>
          <BrinksLogo color={`${color ? color : 'white'}`} />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  .partners-logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    &.mobile {
      display: none;
      .partner-logo {
        width: 50%;

        &.wide {
          width: 100%;
        }
      }
    }
  }

  .partner-logo {
    width: 33.3%;
    text-align: center;

    &.wide {
      width: 50%;
    }

    img {
      height: auto;
    }

    &.hra img {
      width: 40%;
    }

    &.db img {
      width: 55%;
    }

    &.gemma img {
      width: 25%;
    }

    &.priority img {
      width: 25%;
    }

    &.brinks img {
      width: 25%;
    }
  }

  @media (max-width: 768px) {
    .partners-logos {
      display: none;

      &.mobile {
        display: flex;
      }
    }

    .partner-logo {
      &.priority img {
        width: 40%;
      }
    }
  }
`

export default Partners
