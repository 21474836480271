import styled from 'styled-components'
import { useAppContext } from '../../context/appContext'
import ShopOrdersItem from './ShopOrdersItem'

const ShopOrders = ({ items }) => {
  const { i18n } = useAppContext()

  return (
    <Wrapper>
      <div className='order-title'>{i18n.yourOrders}</div>
      {items.map((item, index) => {
        const status = item.statuses[0]

        return <ShopOrdersItem key={index} item={item} status={status} />
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding: 50px 20%;

  .order-title {
    font-size: var(--f-size-200);
    font-weight: 600;
  }
  .order-item {
    font-size: var(--f-size-100);
    color: var(--black);
    background: var(--grey-100);
    padding: 10px 30px;
    border-radius: 5px;
    margin: 10px;

    &.CONFIRMED {
      background: var(--gold);
    }

    &.DECLINED {
      background: var(--red-light);
    }
  }

  .order-info {
    cursor: pointer;
  }

  .order-num {
    font-size: var(--f-size-200);
    font-weight: 600;
  }

  @media (max-width: 460px) {
    padding: 50px 20px;
  }
`

export default ShopOrders
