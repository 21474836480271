import React, { useReducer, useContext } from 'react'
import {
  CLEAR_ALERT,
  DISPLAY_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  SET_DATA_TABLE,
  SET_DICTIONARY,
  TOGGLE_MENU,
  TOGGLE_SEARCH,
  SET_USER_INVENTORY,
  CHANGE_LANGUAGE,
} from './actions'
import reducer from './reducer'
import axios from 'axios'
import { MN, ENG } from '../utils/language'

const token = localStorage.getItem('token')
const user = localStorage.getItem('user')
const userLocation = localStorage.getItem('location')
const lang = localStorage.getItem('lang')

const initialState = {
  isLoading: false,
  showAlert: false,
  alertText: '',
  alertType: '',
  user: user ? JSON.parse(user) : null,
  token: token,
  userLocation: userLocation || '',
  showSidebar: false,
  showMenu: false,
  showSearch: false,
  delay: 300000,
  dataTables: {
    mainSearch: {
      search: '',
      price: '',
    },
  },
  dictionary: {},
  userInventory: [],
  exchangeAmount: 0,
  lang: lang === 'MN' ? lang : 'ENG',
  i18n: lang === 'MN' ? MN : ENG,
}

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const authFetch = axios.create({
    baseURL: '/api/v1',
  })

  authFetch.interceptors.request.use(
    (config) => {
      config.headers.common['Authorization'] = `Bearer ${state.token}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  authFetch.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser()
      }
      return Promise.reject(error)
    }
  )

  const fetch = axios.create({
    baseURL: '/api/v1',
  })

  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT })
    clearAlert()
  }

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT })
    }, 3000)
  }

  const addUserToLocalStorage = ({ user, token }) => {
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('token', token)
  }

  const removeUserFromLocalStorage = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
  }

  const setupUser = async ({ currentUser, endPoint }) => {
    dispatch({ type: SETUP_USER_BEGIN })
    try {
      const { data } = await fetch.post(`/auth/${endPoint}`, currentUser)
      const { user, token } = data
      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { user, token },
      })
      addUserToLocalStorage({ user, token })
    } catch (ex) {
      const exData = ex.response.data
      if (exData.statusCode === 409) {
        dispatch({
          type: SETUP_USER_ERROR,
          payload: {
            msg: state.i18n.alreadyEmailRegistered,
          },
        })
      } else {
        dispatch({
          type: SETUP_USER_ERROR,
          payload: {
            msg:
              endPoint === 'login'
                ? state.i18n.wrongEmailOrPassword
                : state.i18n.invalidInvitationCode,
          },
        })
      }
    }
    clearAlert()
  }

  const toggleSidebar = () => {
    dispatch({ type: TOGGLE_SIDEBAR })
  }

  const toggleMenu = () => {
    dispatch({ type: TOGGLE_MENU })
  }

  const toggleSearch = () => {
    dispatch({ type: TOGGLE_SEARCH })
  }

  const logoutUser = () => {
    dispatch({ type: LOGOUT_USER })
    removeUserFromLocalStorage()
  }

  const updateUser = async (currentUser) => {
    dispatch({ type: UPDATE_USER_BEGIN })
    try {
      const { data } = await authFetch.patch('/auth/updateUser', currentUser)

      const { user, location, token } = data

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user, location, token },
      })
      addUserToLocalStorage({ user, location, token })
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: { msg: error.response.data.msg },
        })
      }
    }
    clearAlert()
  }

  const setDataTableFilter = ({ pageKey, filter }) => {
    dispatch({ type: SET_DATA_TABLE, payload: { key: pageKey, filter } })
  }

  const setDictionary = ({ dicKey, dicVal }) => {
    dispatch({
      type: SET_DICTIONARY,
      payload: { key: dicKey, value: dicVal },
    })
  }

  const setUserInventory = ({ inventory, amount }) => {
    dispatch({
      type: SET_USER_INVENTORY,
      payload: { inventory, amount },
    })
  }

  const changeLanguage = ({ lang }) => {
    const payload = {}
    if (lang === 'MN') {
      payload.lang = 'MN'
      payload.i18n = MN
    } else {
      payload.lang = 'ENG'
      payload.i18n = ENG
    }

    dispatch({ type: CHANGE_LANGUAGE, payload })
    localStorage.setItem('lang', lang)
  }

  return (
    <AppContext.Provider
      value={{
        ...state,
        displayAlert,
        setupUser,
        toggleSidebar,
        toggleMenu,
        toggleSearch,
        logoutUser,
        updateUser,
        authFetch,
        fetch,
        setDataTableFilter,
        setDictionary,
        setUserInventory,
        changeLanguage,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const useAppContext = () => {
  return useContext(AppContext)
}

export { AppProvider, initialState, useAppContext }
