import { useAppContext } from '../../context/appContext'
import { StatsContainer, Loading } from '../../components'

const Stats = () => {
  const { isLoading } = useAppContext()

  // useEffect(() => {
  //   showStats()
  //   // eslint-disable-next-line
  // }, [])

  if (isLoading) {
    return <Loading center />
  }

  return (
    <>
      <StatsContainer />
      {/* {monthlyApplications.length > 0 && <ChartsContainer />} */}
    </>
  )
}

export default Stats
