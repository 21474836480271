import styled from 'styled-components'
import { RiSearch2Line } from 'react-icons/ri'
import Portal from '../Portal'
import Button from '../Buttons/Button'
import { useState } from 'react'
import { useAppContext } from '../../context/appContext'
import { useNavigate } from 'react-router-dom'

const Search = () => {
  const pageKey = 'mainSearch'
  const { setDataTableFilter, dataTables, showSearch, toggleSearch, i18n } =
    useAppContext()
  const navigate = useNavigate()

  const [search, setSearch] = useState(dataTables[pageKey].search)
  const [price, setPrice] = useState(dataTables[pageKey].price)

  const handleClickSearch = () => {
    setDataTableFilter({ pageKey, filter: { search, price } })
    toggleSearch()
    navigate('/search')
  }

  const handleClickClear = () => {
    setSearch('')
    setPrice('')
    setDataTableFilter({ pageKey, filter: { search: '', price: '' } })
  }

  return (
    showSearch && (
      <Portal>
        <Wrapper>
          <div className='search-input'>
            <RiSearch2Line className='search-icon' />
            <input
              type={'text'}
              value={search}
              placeholder={i18n.enterSearchValue}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className='search-price'>
            <div className='price-select-label'>{i18n.price}:</div>
            <div className='price-select'>
              <select value={price} onChange={(e) => setPrice(e.target.value)}>
                <option value={''}>{i18n.select}</option>
                <option value={'<500'}>{'< 500'}</option>
                <option value={'500-1000'}>500 - 1000</option>
                <option value={'1000-2000'}>1000 - 2000</option>
                <option value={'2000-3000'}>2000 - 3000</option>
                <option value={'3000-4000'}>3000 - 4000</option>
                <option value={'4000-5000'}>4000 - 5000</option>
                <option value={'5000<'}>{'5000 <'}</option>
              </select>
            </div>
          </div>
          <div className='search-button'>
            <Button text={i18n.search} onClick={handleClickSearch} />
            <Button
              text={i18n.clear}
              color={'white'}
              onClick={handleClickClear}
            />
          </div>
        </Wrapper>
      </Portal>
    )
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  top: var(--shop-nav-height);
  background: var(--grey-50);
  padding: 30px 30%;
  text-align: center;

  .search-input {
    position: relative;
    input {
      width: 100%;
      padding: 10px 30px;
      border-radius: 20px;
      border: 1px solid var(--grey-200);
      outline: none;
      font-size: var(--f-size-200);
      color: var(--grey-700);
    }

    .search-icon {
      position: absolute;
      left: 10px;
      top: 12px;
      color: var(--grey-500);
    }
  }

  .search-price {
    width: 100%;
    text-align: left;
    margin-top: 20px;
  }

  .price-select {
    select {
      width: 200px;
      height: 40px;
      padding: 0 20px;
      border-radius: 20px;
      border: 1px solid var(--grey-200);
      background: var(--grey-50);
    }
  }

  .price-select-label {
    font-size: var(--f-size-100);
    color: var(--black);
  }

  .search-button {
    margin-top: 20px;

    button {
      display: inline-block;
      width: 100px;
      margin: 10px;
    }
  }

  @media (max-width: 502px) {
    padding: 30px 30px;
  }
`

export default Search
