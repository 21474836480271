import Wrapper from '../../wrappers/Product'
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  Loading,
  ShopProductDetail,
  ShopRelatedProducts,
} from '../../components'
import { useAppContext } from '../../context/appContext'
import {
  DICTIONARY_CATEGORY,
  DICTIONARY_COLLECTION,
} from '../../utils/constants'

const ShopProduct = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { authFetch, i18n, lang } = useAppContext()
  const [title, setSetTitle] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const [pageId, setPageId] = useState()
  const from = useRef()
  const [orderQuantity, setOrderQuantity] = useState(1)
  const [related, setRelated] = useState([])

  const fetchData = async () => {
    try {
      setLoading(true)
      const { data: resData } = await authFetch.get(`/products/${pageId}`)

      setSetTitle(resData.product?.product_num)
      setData(resData.product)

      const { data: relatedData } = await authFetch.get('/products/related')
      setRelated(relatedData.rows)

      setLoading(false)
    } catch (ex) {
      setLoading(false)
      console.log(ex)
      navigate('/404', { replace: true })
    }
  }

  const changeOrderQuantity = (decrease) => {
    if (decrease) {
      if (orderQuantity === 1) {
        return false
      }
      setOrderQuantity(orderQuantity - 1)
    } else {
      if (orderQuantity + 1 > data.inventory.quantity) {
        return false
      }
      setOrderQuantity(orderQuantity + 1)
    }
  }

  useEffect(() => {
    if (pageId) {
      fetchData()
    }

    // eslint-disable-next-line
  }, [pageId])

  useEffect(() => {
    const stateId = location.state?.id
    const stateFrom = location.state?.from

    if (stateFrom) {
      from.current = stateFrom
    } else {
      from.current = DICTIONARY_CATEGORY
    }

    if (stateId) {
      setPageId(stateId)
    } else {
      setPageId(id)
    }

    // eslint-disable-next-line
  }, [location])

  return (
    <Wrapper>
      {loading ? (
        <div className='loading-container'>
          <Loading color={'black'} />
        </div>
      ) : (
        <>
          <div className='title'>{title}</div>
          <div className='route-map'>
            <span onClick={() => navigate('/')}>{i18n.home}</span>/
            {from.current === DICTIONARY_COLLECTION && data.collection ? (
              <>
                <span onClick={() => navigate('/collections')}>
                  {' '}
                  {i18n.collections}
                </span>
                /
                <span
                  onClick={() =>
                    navigate(`/collection/${data.collection_id}`, {
                      state: { id: data.collection_id },
                    })
                  }
                >
                  {' '}
                  {lang === 'ENG'
                    ? data.collection?.name_en
                    : data.collection?.name_mn}
                </span>
              </>
            ) : (
              <span
                onClick={() =>
                  navigate(`/category/${data.category_id}`, {
                    state: { id: data.category_id },
                  })
                }
              >
                {' '}
                {lang === 'ENG'
                  ? data.category?.name_en
                  : data.category?.name_mn}
              </span>
            )}
            /<span className='self'> {title}</span>
          </div>
          <ShopProductDetail
            data={data}
            value={orderQuantity}
            decrease={() => changeOrderQuantity(true)}
            increase={() => changeOrderQuantity(false)}
          />
          <ShopRelatedProducts items={related} from={from} />
        </>
      )}
    </Wrapper>
  )
}

export default ShopProduct
