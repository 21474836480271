import styled from 'styled-components'
import { MdLocalPhone, MdEmail } from 'react-icons/md'

const Footer = () => {
  return (
    <Wrapper>
      <div className='phone'>
        <MdLocalPhone className='icon' />
        +976 - 94113168
      </div>

      <div className='email'>
        <MdEmail className='icon' />
        info@gemmashine.mn
      </div>

      <div className='copyright'>
        Created by Ving LLC | Copyright © 2022 Gemma Shine LLC
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;

  div {
    display: inline-block;
    margin: 0 20px;
    color: var(--black);
    font-size: var(--f-size-100);
  }
  .icon {
    margin: 0 10px -7px;
    font-size: var(--f-size-500);
  }
  .phone {
    /* min-width: 163px; */
  }

  .email {
    /* min-width: 230px; */
  }

  .copyright {
    /* min-width: 468px; */
  }

  @media (max-width: 500px) {
    .copyright {
      min-width: auto;
    }
  }
`

export default Footer
