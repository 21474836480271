import styled from 'styled-components'

const Wrapper = styled.nav`
  width: 100%;
  min-height: var(--shop-nav-height);
  background: var(--primary-500);
  color: var(--white);
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 9;
  top: 0;

  a {
    color: var(--white);
  }

  .east {
    display: flex;
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
    cursor: pointer;
  }

  h5 {
    color: var(--gold);
    font-weight: 700;
    margin: 0;
    line-height: normal;
    text-align: center;
  }

  .h6 {
    font-size: var(--size-100);
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .collection {
    font-size: var(--f-size-200);
    padding: 0 34px;
    text-transform: uppercase;
    border: solid 1px var(--white);
    border-top: none;
    border-bottom: none;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }

  .west {
    display: flex;
    align-items: center;
    font-size: var(--f-size-100);
  }

  .categories {
    display: flex;
    align-items: center;
    height: 100%;

    div {
      margin: 0 24px;
    }
  }

  .profile {
    padding: 0 20px;
    border: solid 1px var(--white);
    border-top: none;
    border-bottom: none;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .margined {
    margin: 0 20px;
  }

  .search {
    border: solid 1px var(--white);
    border-radius: 50%;
    padding: 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: var(--f-size-100);
  }

  .pack {
    font-size: var(--f-size-500);
    cursor: pointer;
    position: relative;

    .badge {
      background: var(--red-dark);
      border-radius: 50%;
      top: 3px;
      right: -8px;
      position: absolute;
      font-size: var(--f-size-50);
      line-height: normal;
      min-width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon {
      margin-top: 10px;
    }
  }

  .profile-text {
    .icon {
      font-size: var(--f-size-500);
      cursor: pointer;
      margin-top: 6px;
    }
  }

  .lang {
    padding: 0 16px;
    min-width: 94px;

    span {
      cursor: pointer;
    }

    .selected {
      font-weight: 700;
    }
  }

  .menu-btn {
    font-size: 26px;
    display: block;
    cursor: pointer;

    .icon {
      margin-top: 15px;
    }
  }

  @media (max-width: 1415px) {
    .categories {
      display: none;
    }

    .menu-btn {
      display: block;
    }
    .collection {
      border-right: none;
    }

    .profile {
      border-left: none;
    }
  }

  @media (max-width: 831px) {
    .lang {
      display: none;
    }
  }

  @media (max-width: 739px) {
    .profile-text {
      display: none;
    }

    .up-line {
      display: none;
    }

    .margined {
      margin: 0 10px;
    }
  }

  @media (max-width: 582px) {
    .collection {
      display: none;
    }

    h5 {
      font-size: var(--f-size-100);
    }

    .h6 {
      font-size: var(--f-size-50);
    }
  }
`

export default Wrapper
