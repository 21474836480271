import { useState } from 'react'

const useValidate = ({ items, nested }) => {
  const [errors, setErrors] = useState({})
  const [isValid, setIsValid] = useState(false)

  const validate = ({ name, value }) => {
    const item = items[name]
    const message = item.message ? item.message : 'Invalid value'
    const error = {}
    let on = ''

    if (item.maxLength && item.maxLength < value.length) {
      error[name] = message
      on = 'maxLength'
    }

    if (item.minLength && item.minLength > value.length) {
      error[name] = message
      on = 'minLength'
    }

    if (item.required && (!value || value === '' || value === 'false')) {
      error[name] = message
      on = 'required'
    }

    if (item.pattern && !item.pattern.test(value)) {
      error[name] = message
      on = 'pattern'
    }

    return { error, on }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const item = items[name]
    const { error, on } = validate({ name, value })

    if (item.ignoreSet) {
      if (Object.keys(error).length > 0 && value !== '' && on !== 'minLength') {
        return false
      }
    }

    if (item.nested || nested) {
      item.onChange({ name, value })
    } else {
      item.onChange(value)
    }

    if (item.ignoreOn) {
      return false
    }

    let retErrors = { ...errors }

    if (Object.keys(error).length > 0) {
      retErrors = { ...errors, ...error }
    } else {
      delete retErrors[name]
    }

    if (Object.keys(retErrors).length > 0) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }

    setErrors({ ...retErrors })
  }

  const handleSubmit = (e, callback) => {
    e.preventDefault()
    let validatedErrors = {}
    const keys = Object.keys(items)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      const { error } = validate({ name: key, value: items[key].value })
      validatedErrors = { ...validatedErrors, ...error }
    }
    console.log(validatedErrors)
    if (Object.keys(validatedErrors).length > 0) {
      setIsValid(false)
    } else {
      setIsValid(true)
      callback()
    }
    setErrors(validatedErrors)
  }

  return { handleChange, handleSubmit, errors, setErrors, isValid, validate }
}

export default useValidate
