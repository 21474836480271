import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAppContext } from '../../context/appContext'

const ShopCategories = ({ data }) => {
  const navigate = useNavigate()
  const { i18n, lang } = useAppContext()

  const prepare = (rows) => {
    const array = [...rows]
    const nestedArr = []

    for (let i = 0; i < array.length; i += 2) {
      nestedArr.push(array.slice(i, i + 2))
    }

    return nestedArr
  }

  return (
    <Wrapper>
      <div className='title'>{i18n.categories}</div>
      {prepare(data).map((row, index) => {
        return (
          <div className='row' key={index}>
            {row.map((item, itemIndex) => {
              return (
                <div
                  key={itemIndex}
                  className='item'
                  onClick={() =>
                    navigate(`/category/${item.id}`, { state: { id: item.id } })
                  }
                >
                  <div className='img'>
                    <img src={item.cover} alt={item.name_en} />
                  </div>
                  <div className='name'>
                    {lang === 'ENG' ? item.name_en : item.name_mn}
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .title {
    font-size: var(--f-size-300);
    color: var(--black);
    margin-bottom: 28px;
    text-align: center;
    font-weight: 600;
  }

  .name {
    font-size: var(--f-size-200);
    text-align: center;
    width: 100%;
    padding: 15px 0;
  }

  .row {
    text-align: center;
    width: 100%;
  }

  .item {
    cursor: pointer;
    display: inline-block;
    margin: 0 2px;
  }

  .img {
    height: 322px;
    overflow: hidden;
    &:hover img {
      transform: scale(1.1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    object-fit: cover;
  }
`

export default ShopCategories
