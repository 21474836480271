import styled, { keyframes } from 'styled-components'
import { ImSpinner9 } from 'react-icons/im'

const Loading = ({ color, fontSize }) => {
  return (
    <Loader>
      <ImSpinner9
        className={`spinner ${color}`}
        style={{ fontSize: fontSize }}
      />
    </Loader>
  )
}

const spin = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`

const Loader = styled.div`
  .spinner {
    animation-name: ${spin};
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    color: var(--white);
    font-size: var(--size);
    &.black {
      color: var(--black);
    }
  }
`

export default Loading
