import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAppContext } from '../../context/appContext'

const ShopRelatedProducts = ({ items, from }) => {
  const navigate = useNavigate()

  const { i18n } = useAppContext()

  return (
    <Wrapper>
      <div className='related-title'>{i18n.similarProducts}</div>
      {items.map((item, index) => {
        return (
          <div
            key={index}
            className='related-items'
            onClick={() =>
              navigate(`/product/${item.id}`, {
                state: { id: item.id, from: from },
              })
            }
          >
            <div className='item'>
              <img src={item.cover} alt={item.product_num} />
            </div>
          </div>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100;
  text-align: center;
  margin-top: 146px;

  .related-title {
    color: var(--black);
    font-size: var(--f-size-200);
  }
  .related-items {
    width: 400px;
    margin: 10px;
    text-align: center;
    display: inline-block;
    border: solid 1px var(--grey-100);
    cursor: pointer;
    &:hover img {
      transform: scale(1.1);
    }
  }

  .item {
    aspect-ratio: 1 / 1;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;
    }
  }

  @media (max-width: 670px) {
    .related-items {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
`

export default ShopRelatedProducts
