import styled from 'styled-components'
import { useAppContext } from '../../context/appContext'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, ShopInventory, SuccessModal } from '../../components'
import { formatAmount } from '../../utils'
import { useState, useRef, useEffect } from 'react'

const ShopSendOrder = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { userInventory, exchangeAmount, authFetch, setUserInventory, i18n } =
    useAppContext()
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line
  const [iniLoading, setInitLoading] = useState(false)
  const [show, setShow] = useState(false)
  const orderRef = useRef()
  const [data, setData] = useState()
  const [pageId, setPageId] = useState()
  const location = useLocation()
  const [errors, setErrors] = useState([])

  const calculateTotalPrice = ({ items }) => {
    let price = 0

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      price += item.quantity * item.product.inventory.price
    }

    let amount = Math.ceil(price * exchangeAmount)
    amount = Math.ceil((amount / 100) * 30)

    return formatAmount({ amount, type: 'MN' })
  }

  const sendOrder = async () => {
    try {
      setLoading(true)
      const { data: orderData } = await authFetch.post(`order`)
      orderRef.current = orderData.order.id

      const { data: inventory } = await authFetch(`userinventory`)
      setUserInventory({
        inventory: inventory.rows ? inventory.rows : [],
        amount: inventory.exchange_amount ? inventory.exchange_amount : 0,
      })

      setShow(true)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
      const exData = ex.response.data
      console.log(exData)
      if (exData.key === 'NOT_ENOUGH') {
        setErrors(exData.errors.items)
        setShow(true)
      }
    }
  }

  const fetchOrder = async () => {
    try {
      setInitLoading(true)
      const { data: orderData } = await authFetch.get(`order/${pageId}`)
      setData(orderData.order)
      setInitLoading(false)
    } catch (ex) {
      setInitLoading(true)
      const { data: orderData } = await authFetch.get(`order/${pageId}`)
      orderRef.current = orderData.order.id
      setShow(true)
      setInitLoading(false)
    }
  }

  useEffect(() => {
    if (pageId) {
      fetchOrder()
    }

    // eslint-disable-next-line
  }, [pageId])

  useEffect(() => {
    const stateId = location.state?.id
    if (stateId) {
      setPageId(stateId)
    } else {
      setPageId(id)
    }

    // eslint-disable-next-line
  }, [location])

  return (
    <Wrapper>
      <div className='order-item payment-info'>
        <div className='payment-title'>{i18n.completeYourOrder}</div>
        <div className='text-item'>
          <div>{i18n.bankName}: </div>
          <div className='bold'>Khan Bank</div>
        </div>
        <div className='text-item'>
          <div>{i18n.bankAccountName}: </div>
          <div className='bold'>Жемма Шайн ХХК</div>
        </div>
        <div className='text-item'>
          <div>{i18n.bankAccountNumber}: </div>
          <div className='bold'>5431515764</div>
        </div>
        {data && (
          <div className='text-item'>
            <div>{i18n.transactionNote}</div>
            <div className='bold'>{data.order_num}</div>
          </div>
        )}
        <div className='text-item'>
          <div>{i18n.totalPrice}</div>
          <div className='bold'>
            {calculateTotalPrice({
              items: data ? data.products : userInventory,
            })}
          </div>
        </div>
        {!data && (
          <div className='order-button'>
            <span className='bold'>{i18n.orderRequest}</span>
            <Button text={i18n.send} onClick={sendOrder} loading={loading} />
          </div>
        )}
      </div>
      <div className='order-item order-info'>
        {data && (
          <div className='order-num'>
            {i18n.orderNumber}: <span className='bold'>{data.order_num}</span>
          </div>
        )}
        <ShopInventory
          items={data ? data.products : userInventory}
          wrapped={true}
          unCloseAble={data ? true : false}
          errors={errors}
        />
      </div>
      <SuccessModal
        setShow={setShow}
        show={show}
        callback={() => {
          navigate(`/order/${orderRef.current}`, {
            state: { id: orderRef.current },
            replace: true,
          })
        }}
        errors={errors}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 40px 15px;
  width: 100%;
  text-align: center;
  color: var(--black);
  font-size: var(--f-size-100);

  .order-item {
    display: inline-block;
  }

  .payment-info {
    width: 250px;
  }

  .order-info {
    max-width: 630px;
    min-width: 420px;
    width: calc(100% - 260px);
    margin-left: 10px;
    vertical-align: top;
  }

  .order-num {
    font-size: var(--f-size-300);
    text-align: left;
  }

  .bold {
    font-weight: 700;
  }

  .payment-title {
    font-size: var(--f-size-300);
    margin-bottom: 30px;
  }

  .text-item {
    margin: 15px 0;
  }

  .order-button {
    margin: 30px 0;
    padding: 0 10px;
  }
  @media (max-width: 500px) {
    .order-info {
      min-width: 250px;
    }
  }
`

export default ShopSendOrder
