import logoWhite from '../../assets/images/hra-logo-white.png'
import logoBlack from '../../assets/images/hra-logo-black.png'

const HraGroupLogo = ({ color }) => {
  if (color === 'white') {
    return <img src={logoWhite} alt='HRA GROUP' className='logo' />
  } else {
    return <img src={logoBlack} alt='HRA GROUP' className='logo' />
  }
}

export default HraGroupLogo
