import { useAppContext } from '../../../context/appContext'
import { useState, useEffect } from 'react'
import { formatAmount, formatDate, objToQuery } from '../../../utils'
import {
  DataTableV,
  MenuCell,
  NestedCell,
  OrderStatusChangeModal,
  StatusCell,
} from '../../../components'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import Wrapper from '../../../wrappers/ListWrapper'
import {
  ORDER_STATUS_CONFIRMED,
  ORDER_STATUS_DECLINED,
  ORDER_STATUS_PENDING,
} from '../../../utils/constants'
import moment from 'moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const OrderList = () => {
  const { authFetch, setDataTableFilter, dataTables } = useAppContext()
  const [rowsData, setRowsData] = useState([])
  const [rowsTotalCount, setRowsTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState({ open: false })

  const defaultFilter = {
    order_num: '',
    name: '',
    phone: '',
    email: '',
    status: 'ALL',
    page: 0,
    limit: 10,
    start_date: null,
    end_date: null,
  }
  const pageKey = 'OrderList'

  const [filter, setFilter] = useState(
    dataTables[pageKey] ? dataTables[pageKey] : defaultFilter
  )

  const fetchData = async () => {
    try {
      setLoading(true)
      setDataTableFilter({ pageKey, filter })
      const { data } = await authFetch(
        `order${objToQuery({
          ...filter,
          page: filter.page + 1,
        })}`
      )

      setRowsData(data.rows)
      setRowsTotalCount(data.count)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
      setRowsData([])
      setRowsTotalCount(0)
    }
  }

  const handleChangePage = (event, newPage) => {
    filter.page = newPage
    setFilter({ ...filter })
    fetchData()
  }

  const handleChangeRowsPerPage = (event) => {
    filter.limit = event.target.value
    filter.page = 0
    setFilter({ ...filter })
    fetchData()
  }

  const handleFilterChange = (event) => {
    const { value, name, type } = event.target

    if (type === 'start_date' || type === 'end_date') {
      const localDate = moment(value)
      const utcDate = localDate.utc()

      if (type === 'start_date') {
        utcDate.startOf('day')
      } else if (type === 'end_date') {
        utcDate.endOf('day')
      }

      setFilter({ ...filter, [name]: utcDate })
    } else {
      setFilter({ ...filter, [name]: value })
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [])

  const columns = [
    { id: 'order_num', label: 'Order Number' },
    {
      id: 'ownedBy',
      label: 'Owned by',
      render: ({ row }) => {
        return <NestedCell data={row.ownedBy} name={'name'} />
      },
    },
    {
      id: 'email',
      label: 'email',
      render: ({ row }) => {
        return <NestedCell data={row.ownedBy} name={'email'} />
      },
    },
    {
      id: 'phone',
      label: 'phone',
      render: ({ row }) => {
        return <NestedCell data={row.ownedBy} name={'phone'} />
      },
    },
    {
      id: 'total_amount',
      label: 'total',
      render: ({ row }) => {
        return formatAmount({ amount: row.total_amount })
      },
    },
    {
      id: 'pre_amount',
      label: 'pre',
      render: ({ row }) => {
        return formatAmount({ amount: row.pre_amount })
      },
    },
    {
      id: 'exchange_rate',
      label: 'rate',
      render: ({ row }) => {
        return formatAmount({ amount: row.exchange_rate, type: 'MN' })
      },
    },
    {
      id: 'total_amount_tug',
      label: 'total',
      render: ({ row }) => {
        return formatAmount({
          amount: row.total_amount * row.exchange_rate,
          type: 'MN',
        })
      },
    },
    {
      id: 'pre_amount_tug',
      label: 'pre',
      render: ({ row }) => {
        return formatAmount({
          amount: row.pre_amount * row.exchange_rate,
          type: 'MN',
        })
      },
    },
    {
      id: 'ordered date',
      label: 'date',
      render: ({ row }) => {
        return formatDate({ date: row.createdAt })
      },
    },
    {
      id: 'status',
      label: 'status',
      render: ({ row }) => {
        let status = ''

        if (row.statuses.length > 0) {
          status = row.statuses[0].status
        }

        if (status === ORDER_STATUS_PENDING) {
          return <StatusCell text={status} />
        }
        if (status === ORDER_STATUS_CONFIRMED) {
          return <StatusCell text={status} status='success' />
        }
        if (status === ORDER_STATUS_DECLINED) {
          return <StatusCell text={status} status='danger' />
        }
      },
    },
    {
      id: 'menu',
      label: '',
      type: 'rowNumber',
      render: ({ row }) => {
        return (
          <MenuCell
            items={[
              {
                text: 'view',
                onClick: () => {
                  navigate(`/dashboard/order/${row.id}`)
                },
              },
              {
                text: 'change status',
                onClick: () => {
                  setShowModal({ open: true, data: row })
                },
              },
            ]}
          />
        )
      },
    },
  ]

  return (
    <Wrapper>
      <Stack
        spacing={2}
        direction='row'
        alignItems={'center'}
        justifyContent={'flex-start'}
        mb={'10px'}
      >
        <TextField
          name='order_num'
          label='order number'
          variant='outlined'
          value={filter.order_num}
          onChange={handleFilterChange}
          size={'small'}
        />
        <TextField
          name='name'
          label='name'
          variant='outlined'
          value={filter.name}
          onChange={handleFilterChange}
          size={'small'}
        />
        <TextField
          name='phone'
          label='phone'
          variant='outlined'
          value={filter.phone}
          onChange={handleFilterChange}
          size={'small'}
        />
        <TextField
          name='email'
          label='email'
          variant='outlined'
          value={filter.email}
          onChange={handleFilterChange}
          size={'small'}
        />
      </Stack>
      <Stack
        spacing={2}
        direction='row'
        alignItems={'center'}
        justifyContent={'flex-start'}
        mb={'20px'}
      >
        <FormControl size={'small'} className='form-field'>
          <InputLabel>status</InputLabel>
          <Select
            name='status'
            label='status'
            value={filter.status}
            onChange={handleFilterChange}
          >
            <MenuItem value={'ALL'}>All</MenuItem>
            <MenuItem value={ORDER_STATUS_CONFIRMED}>Confirmed</MenuItem>
            <MenuItem value={ORDER_STATUS_PENDING}>Pending</MenuItem>
            <MenuItem value={ORDER_STATUS_DECLINED}>Decline</MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label='start date'
            inputFormat='MM/DD/YYYY'
            value={filter.start_date}
            onChange={(value) =>
              handleFilterChange({
                target: {
                  value,
                  name: 'start_date',
                  type: 'start_date',
                },
              })
            }
            renderInput={(params) => <TextField {...params} size={'small'} />}
          />
          <DatePicker
            label='end date'
            inputFormat='MM/DD/YYYY'
            value={filter.end_date}
            onChange={(value) =>
              handleFilterChange({
                target: {
                  value,
                  name: 'end_date',
                  type: 'end_date',
                },
              })
            }
            renderInput={(params) => <TextField {...params} size={'small'} />}
          />
        </LocalizationProvider>
        <Button variant='contained' disabled={loading} onClick={fetchData}>
          Search
        </Button>
        <Button
          variant='text'
          disabled={loading}
          onClick={() => setFilter(defaultFilter)}
        >
          Clear
        </Button>
      </Stack>
      <DataTableV
        rows={rowsData}
        columns={columns}
        rowsPerPage={filter.limit}
        page={filter.page}
        count={rowsTotalCount}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
      <OrderStatusChangeModal
        open={showModal.open}
        close={() => setShowModal({ open: false })}
        data={showModal.data}
        submit={() => {
          setShowModal({ open: false })
          fetchData()
        }}
      />
    </Wrapper>
  )
}

export default OrderList
