import styled from 'styled-components'
import { GrClose } from 'react-icons/gr'
import { useAppContext } from '../../context/appContext'
import { useNavigate } from 'react-router-dom'
import { formatAmount } from '../../utils'

const ShopInventory = ({ items, unCloseAble, wrapped, errors }) => {
  const { authFetch, setUserInventory, i18n, lang } = useAppContext()
  const navigate = useNavigate()

  const removeItem = async ({ id }) => {
    try {
      const { data } = await authFetch.get(`userinventory/${id}`)
      setUserInventory({
        inventory: data.rows ? data.rows : [],
        amount: data.exchange_amount ? data.exchange_amount : 0,
      })
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <Wrapper className={wrapped ? 'wrapped' : ''}>
      {items.map((item, index) => {
        const product = item.product
        return (
          <div
            className={`inventory ${
              errors && errors.includes(product.id) ? 'error' : ''
            }`}
            key={index}
          >
            {!unCloseAble && (
              <div className='header'>
                <GrClose
                  className='icon'
                  onClick={() => removeItem({ id: item.id })}
                />
              </div>
            )}
            <div
              className='inventory-item product-image'
              onClick={() =>
                navigate(`/product/${product.id}`, {
                  state: { id: product.id },
                })
              }
            >
              <img src={product.cover} alt={product.product_num} />
            </div>
            <div className='inventory-item product-info'>
              <div className='product-num'>{product.product_num}</div>
              <div className='product-description'>
                {i18n.productDescription}
                <br />
                {lang === 'ENG'
                  ? product.description_en
                  : product.description_mn}
              </div>
              <div className='product-description'>
                {i18n.quantity}: <span className='bold'>{item.quantity}</span>
              </div>
              <div className='product-description'>
                {i18n.price}:{' '}
                <span className='bold'>
                  {formatAmount({ amount: product.inventory?.price })}
                </span>
              </div>
            </div>
          </div>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: var(--black);
  padding: 20px 20%;
  font-size: var(--f-size-100);

  &.wrapped {
    padding: 0;
  }

  .inventory {
    border-bottom: solid 1px var(--grey-100);
    padding: 15px 15px;
    cursor: pointer;

    &.error {
      background: var(--red-light);
    }
  }

  .header {
    text-align: right;
    margin-bottom: 10px;
    .icon {
      font-size: var(--f-size-200);
      cursor: pointer;
    }
  }

  .inventory-item {
    display: inline-block;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .product-image {
    width: 200px;
    height: 200px;
    overflow: hidden;
  }

  .product-info {
    min-width: 200px;
    vertical-align: top;
    margin-left: 20px;
    text-align: left;
    width: calc(100% - 230px);
  }

  .product-description {
    margin-top: 15px;
  }

  .bold {
    font-weight: 700;
  }
  @media (max-width: 890px) {
    padding: 10px 10px;
  }

  @media (max-width: 500px) {
    .product-image {
      width: 100%;
      height: auto;
    }
    .product-info {
      width: 100%;
      min-width: auto;
    }
  }
`

export default ShopInventory
