import moment from 'moment'

const DateCell = ({ date }) => {
  const utcDateTime = moment.utc(date)
  const localDateTime = utcDateTime.local()

  return localDateTime.format('YYYY-MM-DD')
}

export default DateCell
