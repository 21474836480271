import styled from 'styled-components'

const FormRow = ({
  type,
  name,
  value,
  handleChange,
  labelText,
  hideLabel,
  placeholder,
  errorText,
}) => {
  return (
    <Wrapper>
      {!hideLabel && (
        <label htmlFor={name} className='form-label'>
          {labelText || name}
        </label>
      )}
      <input
        type={type}
        value={value}
        name={name}
        onChange={handleChange}
        className={`form-input ${errorText && 'error'}`}
        placeholder={placeholder ? placeholder : labelText}
      />
      {errorText && <p className='error'>{errorText}</p>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;

  label {
    display: block;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  input {
    width: 100%;
    background: var(--white);
    padding: 13px 21px;
    font-size: var(--f-size-100);
    border: solid 1px var(--gold);
    border-radius: 40px;

    &.error {
      border-color: var(--red-dark);
    }
  }

  .error {
    font-size: var(--f-size-50);
    color: var(--red-dark);
  }
`

export default FormRow
